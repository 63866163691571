// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$agenda-name: 'agenda';
$agenda-x: 107px;
$agenda-y: 130px;
$agenda-offset-x: -107px;
$agenda-offset-y: -130px;
$agenda-width: 105px;
$agenda-height: 90px;
$agenda-total-width: 507px;
$agenda-total-height: 426px;
$agenda-image: '/public/dist/img/sprite.png';
$agenda: (107px, 130px, -107px, -130px, 105px, 90px, 507px, 426px, '/public/dist/img/sprite.png', 'agenda', );
$arrow-blue-name: 'arrow-blue';
$arrow-blue-x: 234px;
$arrow-blue-y: 153px;
$arrow-blue-offset-x: -234px;
$arrow-blue-offset-y: -153px;
$arrow-blue-width: 18px;
$arrow-blue-height: 20px;
$arrow-blue-total-width: 507px;
$arrow-blue-total-height: 426px;
$arrow-blue-image: '/public/dist/img/sprite.png';
$arrow-blue: (234px, 153px, -234px, -153px, 18px, 20px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-blue', );
$arrow-green-bottom-name: 'arrow-green-bottom';
$arrow-green-bottom-x: 234px;
$arrow-green-bottom-y: 175px;
$arrow-green-bottom-offset-x: -234px;
$arrow-green-bottom-offset-y: -175px;
$arrow-green-bottom-width: 12px;
$arrow-green-bottom-height: 11px;
$arrow-green-bottom-total-width: 507px;
$arrow-green-bottom-total-height: 426px;
$arrow-green-bottom-image: '/public/dist/img/sprite.png';
$arrow-green-bottom: (234px, 175px, -234px, -175px, 12px, 11px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-green-bottom', );
$arrow-green-right-name: 'arrow-green-right';
$arrow-green-right-x: 236px;
$arrow-green-right-y: 130px;
$arrow-green-right-offset-x: -236px;
$arrow-green-right-offset-y: -130px;
$arrow-green-right-width: 11px;
$arrow-green-right-height: 12px;
$arrow-green-right-total-width: 507px;
$arrow-green-right-total-height: 426px;
$arrow-green-right-image: '/public/dist/img/sprite.png';
$arrow-green-right: (236px, 130px, -236px, -130px, 11px, 12px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-green-right', );
$arrow-green-name: 'arrow-green';
$arrow-green-x: 488px;
$arrow-green-y: 351px;
$arrow-green-offset-x: -488px;
$arrow-green-offset-y: -351px;
$arrow-green-width: 18px;
$arrow-green-height: 20px;
$arrow-green-total-width: 507px;
$arrow-green-total-height: 426px;
$arrow-green-image: '/public/dist/img/sprite.png';
$arrow-green: (488px, 351px, -488px, -351px, 18px, 20px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-green', );
$arrow-pink-name: 'arrow-pink';
$arrow-pink-x: 446px;
$arrow-pink-y: 254px;
$arrow-pink-offset-x: -446px;
$arrow-pink-offset-y: -254px;
$arrow-pink-width: 11px;
$arrow-pink-height: 12px;
$arrow-pink-total-width: 507px;
$arrow-pink-total-height: 426px;
$arrow-pink-image: '/public/dist/img/sprite.png';
$arrow-pink: (446px, 254px, -446px, -254px, 11px, 12px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-pink', );
$arrow-white-menu-name: 'arrow-white-menu';
$arrow-white-menu-x: 497px;
$arrow-white-menu-y: 265px;
$arrow-white-menu-offset-x: -497px;
$arrow-white-menu-offset-y: -265px;
$arrow-white-menu-width: 10px;
$arrow-white-menu-height: 11px;
$arrow-white-menu-total-width: 507px;
$arrow-white-menu-total-height: 426px;
$arrow-white-menu-image: '/public/dist/img/sprite.png';
$arrow-white-menu: (497px, 265px, -497px, -265px, 10px, 11px, 507px, 426px, '/public/dist/img/sprite.png', 'arrow-white-menu', );
$breadcrumb-name: 'breadcrumb';
$breadcrumb-x: 446px;
$breadcrumb-y: 268px;
$breadcrumb-offset-x: -446px;
$breadcrumb-offset-y: -268px;
$breadcrumb-width: 11px;
$breadcrumb-height: 12px;
$breadcrumb-total-width: 507px;
$breadcrumb-total-height: 426px;
$breadcrumb-image: '/public/dist/img/sprite.png';
$breadcrumb: (446px, 268px, -446px, -268px, 11px, 12px, 507px, 426px, '/public/dist/img/sprite.png', 'breadcrumb', );
$bullet-hover-name: 'bullet-hover';
$bullet-hover-x: 490px;
$bullet-hover-y: 382px;
$bullet-hover-offset-x: -490px;
$bullet-hover-offset-y: -382px;
$bullet-hover-width: 15px;
$bullet-hover-height: 16px;
$bullet-hover-total-width: 507px;
$bullet-hover-total-height: 426px;
$bullet-hover-image: '/public/dist/img/sprite.png';
$bullet-hover: (490px, 382px, -490px, -382px, 15px, 16px, 507px, 426px, '/public/dist/img/sprite.png', 'bullet-hover', );
$bullet-off-name: 'bullet-off';
$bullet-off-x: 492px;
$bullet-off-y: 295px;
$bullet-off-offset-x: -492px;
$bullet-off-offset-y: -295px;
$bullet-off-width: 15px;
$bullet-off-height: 16px;
$bullet-off-total-width: 507px;
$bullet-off-total-height: 426px;
$bullet-off-image: '/public/dist/img/sprite.png';
$bullet-off: (492px, 295px, -492px, -295px, 15px, 16px, 507px, 426px, '/public/dist/img/sprite.png', 'bullet-off', );
$bullet-on-name: 'bullet-on';
$bullet-on-x: 492px;
$bullet-on-y: 323px;
$bullet-on-offset-x: -492px;
$bullet-on-offset-y: -323px;
$bullet-on-width: 15px;
$bullet-on-height: 16px;
$bullet-on-total-width: 507px;
$bullet-on-total-height: 426px;
$bullet-on-image: '/public/dist/img/sprite.png';
$bullet-on: (492px, 323px, -492px, -323px, 15px, 16px, 507px, 426px, '/public/dist/img/sprite.png', 'bullet-on', );
$candidature-name: 'candidature';
$candidature-x: 0px;
$candidature-y: 130px;
$candidature-offset-x: 0px;
$candidature-offset-y: -130px;
$candidature-width: 105px;
$candidature-height: 97px;
$candidature-total-width: 507px;
$candidature-total-height: 426px;
$candidature-image: '/public/dist/img/sprite.png';
$candidature: (0px, 130px, 0px, -130px, 105px, 97px, 507px, 426px, '/public/dist/img/sprite.png', 'candidature', );
$catching-up-name: 'catchingUp';
$catching-up-x: 254px;
$catching-up-y: 102px;
$catching-up-offset-x: -254px;
$catching-up-offset-y: -102px;
$catching-up-width: 100px;
$catching-up-height: 100px;
$catching-up-total-width: 507px;
$catching-up-total-height: 426px;
$catching-up-image: '/public/dist/img/sprite.png';
$catching-up: (254px, 102px, -254px, -102px, 100px, 100px, 507px, 426px, '/public/dist/img/sprite.png', 'catchingUp', );
$close-grey-name: 'close-grey';
$close-grey-x: 464px;
$close-grey-y: 382px;
$close-grey-offset-x: -464px;
$close-grey-offset-y: -382px;
$close-grey-width: 24px;
$close-grey-height: 25px;
$close-grey-total-width: 507px;
$close-grey-total-height: 426px;
$close-grey-image: '/public/dist/img/sprite.png';
$close-grey: (464px, 382px, -464px, -382px, 24px, 25px, 507px, 426px, '/public/dist/img/sprite.png', 'close-grey', );
$close-pink-name: 'close-pink';
$close-pink-x: 214px;
$close-pink-y: 130px;
$close-pink-offset-x: -214px;
$close-pink-offset-y: -130px;
$close-pink-width: 20px;
$close-pink-height: 21px;
$close-pink-total-width: 507px;
$close-pink-total-height: 426px;
$close-pink-image: '/public/dist/img/sprite.png';
$close-pink: (214px, 130px, -214px, -130px, 20px, 21px, 507px, 426px, '/public/dist/img/sprite.png', 'close-pink', );
$contact-name: 'contact';
$contact-x: 356px;
$contact-y: 172px;
$contact-offset-x: -356px;
$contact-offset-y: -172px;
$contact-width: 100px;
$contact-height: 64px;
$contact-total-width: 507px;
$contact-total-height: 426px;
$contact-image: '/public/dist/img/sprite.png';
$contact: (356px, 172px, -356px, -172px, 100px, 64px, 507px, 426px, '/public/dist/img/sprite.png', 'contact', );
$contactphone-name: 'contactphone';
$contactphone-x: 66px;
$contactphone-y: 321px;
$contactphone-offset-x: -66px;
$contactphone-offset-y: -321px;
$contactphone-width: 45px;
$contactphone-height: 46px;
$contactphone-total-width: 507px;
$contactphone-total-height: 426px;
$contactphone-image: '/public/dist/img/sprite.png';
$contactphone: (66px, 321px, -66px, -321px, 45px, 46px, 507px, 426px, '/public/dist/img/sprite.png', 'contactphone', );
$date-name: 'date';
$date-x: 107px;
$date-y: 229px;
$date-offset-x: -107px;
$date-offset-y: -229px;
$date-width: 105px;
$date-height: 86px;
$date-total-width: 507px;
$date-total-height: 426px;
$date-image: '/public/dist/img/sprite.png';
$date: (107px, 229px, -107px, -229px, 105px, 86px, 507px, 426px, '/public/dist/img/sprite.png', 'date', );
$doc-name: 'doc';
$doc-x: 56px;
$doc-y: 372px;
$doc-offset-x: -56px;
$doc-offset-y: -372px;
$doc-width: 50px;
$doc-height: 50px;
$doc-total-width: 507px;
$doc-total-height: 426px;
$doc-image: '/public/dist/img/sprite.png';
$doc: (56px, 372px, -56px, -372px, 50px, 50px, 507px, 426px, '/public/dist/img/sprite.png', 'doc', );
$download-green-name: 'download-green';
$download-green-x: 407px;
$download-green-y: 293px;
$download-green-offset-x: -407px;
$download-green-offset-y: -293px;
$download-green-width: 23px;
$download-green-height: 23px;
$download-green-total-width: 507px;
$download-green-total-height: 426px;
$download-green-image: '/public/dist/img/sprite.png';
$download-green: (407px, 293px, -407px, -293px, 23px, 23px, 507px, 426px, '/public/dist/img/sprite.png', 'download-green', );
$download-pink-name: 'download-pink';
$download-pink-x: 432px;
$download-pink-y: 293px;
$download-pink-offset-x: -432px;
$download-pink-offset-y: -293px;
$download-pink-width: 23px;
$download-pink-height: 23px;
$download-pink-total-width: 507px;
$download-pink-total-height: 426px;
$download-pink-image: '/public/dist/img/sprite.png';
$download-pink: (432px, 293px, -432px, -293px, 23px, 23px, 507px, 426px, '/public/dist/img/sprite.png', 'download-pink', );
$download-name: 'download';
$download-x: 0px;
$download-y: 229px;
$download-offset-x: 0px;
$download-offset-y: -229px;
$download-width: 105px;
$download-height: 90px;
$download-total-width: 507px;
$download-total-height: 426px;
$download-image: '/public/dist/img/sprite.png';
$download: (0px, 229px, 0px, -229px, 105px, 90px, 507px, 426px, '/public/dist/img/sprite.png', 'download', );
$event-name: 'event';
$event-x: 214px;
$event-y: 229px;
$event-offset-x: -214px;
$event-offset-y: -229px;
$event-width: 106px;
$event-height: 84px;
$event-total-width: 507px;
$event-total-height: 426px;
$event-image: '/public/dist/img/sprite.png';
$event: (214px, 229px, -214px, -229px, 106px, 84px, 507px, 426px, '/public/dist/img/sprite.png', 'event', );
$eye-blue-name: 'eye-blue';
$eye-blue-x: 327px;
$eye-blue-y: 204px;
$eye-blue-offset-x: -327px;
$eye-blue-offset-y: -204px;
$eye-blue-width: 23px;
$eye-blue-height: 15px;
$eye-blue-total-width: 507px;
$eye-blue-total-height: 426px;
$eye-blue-image: '/public/dist/img/sprite.png';
$eye-blue: (327px, 204px, -327px, -204px, 23px, 15px, 507px, 426px, '/public/dist/img/sprite.png', 'eye-blue', );
$eye-pink-name: 'eye-pink';
$eye-pink-x: 464px;
$eye-pink-y: 409px;
$eye-pink-offset-x: -464px;
$eye-pink-offset-y: -409px;
$eye-pink-width: 23px;
$eye-pink-height: 15px;
$eye-pink-total-width: 507px;
$eye-pink-total-height: 426px;
$eye-pink-image: '/public/dist/img/sprite.png';
$eye-pink: (464px, 409px, -464px, -409px, 23px, 15px, 507px, 426px, '/public/dist/img/sprite.png', 'eye-pink', );
$faq-right-name: 'faq-right';
$faq-right-x: 446px;
$faq-right-y: 282px;
$faq-right-offset-x: -446px;
$faq-right-offset-y: -282px;
$faq-right-width: 13px;
$faq-right-height: 9px;
$faq-right-total-width: 507px;
$faq-right-total-height: 426px;
$faq-right-image: '/public/dist/img/sprite.png';
$faq-right: (446px, 282px, -446px, -282px, 13px, 9px, 507px, 426px, '/public/dist/img/sprite.png', 'faq-right', );
$faq-up-name: 'faq-up';
$faq-up-x: 214px;
$faq-up-y: 197px;
$faq-up-offset-x: -214px;
$faq-up-offset-y: -197px;
$faq-up-width: 11px;
$faq-up-height: 11px;
$faq-up-total-width: 507px;
$faq-up-total-height: 426px;
$faq-up-image: '/public/dist/img/sprite.png';
$faq-up: (214px, 197px, -214px, -197px, 11px, 11px, 507px, 426px, '/public/dist/img/sprite.png', 'faq-up', );
$fast-link-blue-dark-name: 'fast-link-blue-dark';
$fast-link-blue-dark-x: 0px;
$fast-link-blue-dark-y: 0px;
$fast-link-blue-dark-offset-x: 0px;
$fast-link-blue-dark-offset-y: 0px;
$fast-link-blue-dark-width: 83px;
$fast-link-blue-dark-height: 128px;
$fast-link-blue-dark-total-width: 507px;
$fast-link-blue-dark-total-height: 426px;
$fast-link-blue-dark-image: '/public/dist/img/sprite.png';
$fast-link-blue-dark: (0px, 0px, 0px, 0px, 83px, 128px, 507px, 426px, '/public/dist/img/sprite.png', 'fast-link-blue-dark', );
$fast-link-blue-light-name: 'fast-link-blue-light';
$fast-link-blue-light-x: 170px;
$fast-link-blue-light-y: 0px;
$fast-link-blue-light-offset-x: -170px;
$fast-link-blue-light-offset-y: 0px;
$fast-link-blue-light-width: 82px;
$fast-link-blue-light-height: 127px;
$fast-link-blue-light-total-width: 507px;
$fast-link-blue-light-total-height: 426px;
$fast-link-blue-light-image: '/public/dist/img/sprite.png';
$fast-link-blue-light: (170px, 0px, -170px, 0px, 82px, 127px, 507px, 426px, '/public/dist/img/sprite.png', 'fast-link-blue-light', );
$fast-link-green-name: 'fast-link-green';
$fast-link-green-x: 85px;
$fast-link-green-y: 0px;
$fast-link-green-offset-x: -85px;
$fast-link-green-offset-y: 0px;
$fast-link-green-width: 83px;
$fast-link-green-height: 128px;
$fast-link-green-total-width: 507px;
$fast-link-green-total-height: 426px;
$fast-link-green-image: '/public/dist/img/sprite.png';
$fast-link-green: (85px, 0px, -85px, 0px, 83px, 128px, 507px, 426px, '/public/dist/img/sprite.png', 'fast-link-green', );
$fb-name: 'fb';
$fb-x: 464px;
$fb-y: 179px;
$fb-offset-x: -464px;
$fb-offset-y: -179px;
$fb-width: 40px;
$fb-height: 40px;
$fb-total-width: 507px;
$fb-total-height: 426px;
$fb-image: '/public/dist/img/sprite.png';
$fb: (464px, 179px, -464px, -179px, 40px, 40px, 507px, 426px, '/public/dist/img/sprite.png', 'fb', );
$file-name: 'file';
$file-x: 464px;
$file-y: 221px;
$file-offset-x: -464px;
$file-offset-y: -221px;
$file-width: 33px;
$file-height: 42px;
$file-total-width: 507px;
$file-total-height: 426px;
$file-image: '/public/dist/img/sprite.png';
$file: (464px, 221px, -464px, -221px, 33px, 42px, 507px, 426px, '/public/dist/img/sprite.png', 'file', );
$home-name: 'home';
$home-x: 464px;
$home-y: 265px;
$home-offset-x: -464px;
$home-offset-y: -265px;
$home-width: 31px;
$home-height: 28px;
$home-total-width: 507px;
$home-total-height: 426px;
$home-image: '/public/dist/img/sprite.png';
$home: (464px, 265px, -464px, -265px, 31px, 28px, 507px, 426px, '/public/dist/img/sprite.png', 'home', );
$image-name: 'image';
$image-x: 356px;
$image-y: 0px;
$image-offset-x: -356px;
$image-offset-y: 0px;
$image-width: 106px;
$image-height: 84px;
$image-total-width: 507px;
$image-total-height: 426px;
$image-image: '/public/dist/img/sprite.png';
$image: (356px, 0px, -356px, 0px, 106px, 84px, 507px, 426px, '/public/dist/img/sprite.png', 'image', );
$instagram-name: 'instagram';
$instagram-x: 464px;
$instagram-y: 137px;
$instagram-offset-x: -464px;
$instagram-offset-y: -137px;
$instagram-width: 40px;
$instagram-height: 40px;
$instagram-total-width: 507px;
$instagram-total-height: 426px;
$instagram-image: '/public/dist/img/sprite.png';
$instagram: (464px, 137px, -464px, -137px, 40px, 40px, 507px, 426px, '/public/dist/img/sprite.png', 'instagram', );
$job-name: 'job';
$job-x: 254px;
$job-y: 0px;
$job-offset-x: -254px;
$job-offset-y: 0px;
$job-width: 100px;
$job-height: 100px;
$job-total-width: 507px;
$job-total-height: 426px;
$job-image: '/public/dist/img/sprite.png';
$job: (254px, 0px, -254px, 0px, 100px, 100px, 507px, 426px, '/public/dist/img/sprite.png', 'job', );
$larrow-white-name: 'larrow-white';
$larrow-white-x: 214px;
$larrow-white-y: 175px;
$larrow-white-offset-x: -214px;
$larrow-white-offset-y: -175px;
$larrow-white-width: 18px;
$larrow-white-height: 20px;
$larrow-white-total-width: 507px;
$larrow-white-total-height: 426px;
$larrow-white-image: '/public/dist/img/sprite.png';
$larrow-white: (214px, 175px, -214px, -175px, 18px, 20px, 507px, 426px, '/public/dist/img/sprite.png', 'larrow-white', );
$linkedin-name: 'linkedin';
$linkedin-x: 464px;
$linkedin-y: 53px;
$linkedin-offset-x: -464px;
$linkedin-offset-y: -53px;
$linkedin-width: 40px;
$linkedin-height: 40px;
$linkedin-total-width: 507px;
$linkedin-total-height: 426px;
$linkedin-image: '/public/dist/img/sprite.png';
$linkedin: (464px, 53px, -464px, -53px, 40px, 40px, 507px, 426px, '/public/dist/img/sprite.png', 'linkedin', );
$loupe-name: 'loupe';
$loupe-x: 381px;
$loupe-y: 293px;
$loupe-offset-x: -381px;
$loupe-offset-y: -293px;
$loupe-width: 24px;
$loupe-height: 24px;
$loupe-total-width: 507px;
$loupe-total-height: 426px;
$loupe-image: '/public/dist/img/sprite.png';
$loupe: (381px, 293px, -381px, -293px, 24px, 24px, 507px, 426px, '/public/dist/img/sprite.png', 'loupe', );
$map-name: 'map';
$map-x: 464px;
$map-y: 351px;
$map-offset-x: -464px;
$map-offset-y: -351px;
$map-width: 22px;
$map-height: 29px;
$map-total-width: 507px;
$map-total-height: 426px;
$map-image: '/public/dist/img/sprite.png';
$map: (464px, 351px, -464px, -351px, 22px, 29px, 507px, 426px, '/public/dist/img/sprite.png', 'map', );
$menu-left-name: 'menu-left';
$menu-left-x: 446px;
$menu-left-y: 238px;
$menu-left-offset-x: -446px;
$menu-left-offset-y: -238px;
$menu-left-width: 13px;
$menu-left-height: 14px;
$menu-left-total-width: 507px;
$menu-left-total-height: 426px;
$menu-left-image: '/public/dist/img/sprite.png';
$menu-left: (446px, 238px, -446px, -238px, 13px, 14px, 507px, 426px, '/public/dist/img/sprite.png', 'menu-left', );
$menu-right-name: 'menu-right';
$menu-right-x: 489px;
$menu-right-y: 409px;
$menu-right-offset-x: -489px;
$menu-right-offset-y: -409px;
$menu-right-width: 13px;
$menu-right-height: 14px;
$menu-right-total-width: 507px;
$menu-right-total-height: 426px;
$menu-right-image: '/public/dist/img/sprite.png';
$menu-right: (489px, 409px, -489px, -409px, 13px, 14px, 507px, 426px, '/public/dist/img/sprite.png', 'menu-right', );
$more-name: 'more';
$more-x: 464px;
$more-y: 323px;
$more-offset-x: -464px;
$more-offset-y: -323px;
$more-width: 26px;
$more-height: 26px;
$more-total-width: 507px;
$more-total-height: 426px;
$more-image: '/public/dist/img/sprite.png';
$more: (464px, 323px, -464px, -323px, 26px, 26px, 507px, 426px, '/public/dist/img/sprite.png', 'more', );
$museum-name: 'museum';
$museum-x: 356px;
$museum-y: 238px;
$museum-offset-x: -356px;
$museum-offset-y: -238px;
$museum-width: 88px;
$museum-height: 53px;
$museum-total-width: 507px;
$museum-total-height: 426px;
$museum-image: '/public/dist/img/sprite.png';
$museum: (356px, 238px, -356px, -238px, 88px, 53px, 507px, 426px, '/public/dist/img/sprite.png', 'museum', );
$news-name: 'news';
$news-x: 0px;
$news-y: 321px;
$news-offset-x: 0px;
$news-offset-y: -321px;
$news-width: 64px;
$news-height: 49px;
$news-total-width: 507px;
$news-total-height: 426px;
$news-image: '/public/dist/img/sprite.png';
$news: (0px, 321px, 0px, -321px, 64px, 49px, 507px, 426px, '/public/dist/img/sprite.png', 'news', );
$next-name: 'next';
$next-x: 464px;
$next-y: 295px;
$next-offset-x: -464px;
$next-offset-y: -295px;
$next-width: 26px;
$next-height: 26px;
$next-total-width: 507px;
$next-total-height: 426px;
$next-image: '/public/dist/img/sprite.png';
$next: (464px, 295px, -464px, -295px, 26px, 26px, 507px, 426px, '/public/dist/img/sprite.png', 'next', );
$note-name: 'note';
$note-x: 356px;
$note-y: 86px;
$note-offset-x: -356px;
$note-offset-y: -86px;
$note-width: 106px;
$note-height: 84px;
$note-total-width: 507px;
$note-total-height: 426px;
$note-image: '/public/dist/img/sprite.png';
$note: (356px, 86px, -356px, -86px, 106px, 84px, 507px, 426px, '/public/dist/img/sprite.png', 'note', );
$pdf-name: 'pdf';
$pdf-x: 356px;
$pdf-y: 293px;
$pdf-offset-x: -356px;
$pdf-offset-y: -293px;
$pdf-width: 23px;
$pdf-height: 26px;
$pdf-total-width: 507px;
$pdf-total-height: 426px;
$pdf-image: '/public/dist/img/sprite.png';
$pdf: (356px, 293px, -356px, -293px, 23px, 26px, 507px, 426px, '/public/dist/img/sprite.png', 'pdf', );
$pinterest-name: 'pinterest';
$pinterest-x: 464px;
$pinterest-y: 95px;
$pinterest-offset-x: -464px;
$pinterest-offset-y: -95px;
$pinterest-width: 40px;
$pinterest-height: 40px;
$pinterest-total-width: 507px;
$pinterest-total-height: 426px;
$pinterest-image: '/public/dist/img/sprite.png';
$pinterest: (464px, 95px, -464px, -95px, 40px, 40px, 507px, 426px, '/public/dist/img/sprite.png', 'pinterest', );
$quotes-name: 'quotes';
$quotes-x: 254px;
$quotes-y: 204px;
$quotes-offset-x: -254px;
$quotes-offset-y: -204px;
$quotes-width: 24px;
$quotes-height: 20px;
$quotes-total-width: 507px;
$quotes-total-height: 426px;
$quotes-image: '/public/dist/img/sprite.png';
$quotes: (254px, 204px, -254px, -204px, 24px, 20px, 507px, 426px, '/public/dist/img/sprite.png', 'quotes', );
$rarrow-white-name: 'rarrow-white';
$rarrow-white-x: 214px;
$rarrow-white-y: 153px;
$rarrow-white-offset-x: -214px;
$rarrow-white-offset-y: -153px;
$rarrow-white-width: 18px;
$rarrow-white-height: 20px;
$rarrow-white-total-width: 507px;
$rarrow-white-total-height: 426px;
$rarrow-white-image: '/public/dist/img/sprite.png';
$rarrow-white: (214px, 153px, -214px, -153px, 18px, 20px, 507px, 426px, '/public/dist/img/sprite.png', 'rarrow-white', );
$tarrow-blue-name: 'tarrow-blue';
$tarrow-blue-x: 305px;
$tarrow-blue-y: 204px;
$tarrow-blue-offset-x: -305px;
$tarrow-blue-offset-y: -204px;
$tarrow-blue-width: 20px;
$tarrow-blue-height: 18px;
$tarrow-blue-total-width: 507px;
$tarrow-blue-total-height: 426px;
$tarrow-blue-image: '/public/dist/img/sprite.png';
$tarrow-blue: (305px, 204px, -305px, -204px, 20px, 18px, 507px, 426px, '/public/dist/img/sprite.png', 'tarrow-blue', );
$triangle-name: 'triangle';
$triangle-x: 0px;
$triangle-y: 372px;
$triangle-offset-x: 0px;
$triangle-offset-y: -372px;
$triangle-width: 54px;
$triangle-height: 54px;
$triangle-total-width: 507px;
$triangle-total-height: 426px;
$triangle-image: '/public/dist/img/sprite.png';
$triangle: (0px, 372px, 0px, -372px, 54px, 54px, 507px, 426px, '/public/dist/img/sprite.png', 'triangle', );
$users-name: 'users';
$users-x: 464px;
$users-y: 0px;
$users-offset-x: -464px;
$users-offset-y: 0px;
$users-width: 43px;
$users-height: 51px;
$users-total-width: 507px;
$users-total-height: 426px;
$users-image: '/public/dist/img/sprite.png';
$users: (464px, 0px, -464px, 0px, 43px, 51px, 507px, 426px, '/public/dist/img/sprite.png', 'users', );
$zoom-name: 'zoom';
$zoom-x: 280px;
$zoom-y: 204px;
$zoom-offset-x: -280px;
$zoom-offset-y: -204px;
$zoom-width: 23px;
$zoom-height: 19px;
$zoom-total-width: 507px;
$zoom-total-height: 426px;
$zoom-image: '/public/dist/img/sprite.png';
$zoom: (280px, 204px, -280px, -204px, 23px, 19px, 507px, 426px, '/public/dist/img/sprite.png', 'zoom', );
$agenda-2x-name: 'agenda@2x';
$agenda-2x-x: 214px;
$agenda-2x-y: 260px;
$agenda-2x-offset-x: -214px;
$agenda-2x-offset-y: -260px;
$agenda-2x-width: 210px;
$agenda-2x-height: 180px;
$agenda-2x-total-width: 1014px;
$agenda-2x-total-height: 852px;
$agenda-2x-image: '/public/dist/img/sprite@2x.png';
$agenda-2x: (214px, 260px, -214px, -260px, 210px, 180px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'agenda@2x', );
$arrow-blue-2x-name: 'arrow-blue@2x';
$arrow-blue-2x-x: 468px;
$arrow-blue-2x-y: 306px;
$arrow-blue-2x-offset-x: -468px;
$arrow-blue-2x-offset-y: -306px;
$arrow-blue-2x-width: 36px;
$arrow-blue-2x-height: 40px;
$arrow-blue-2x-total-width: 1014px;
$arrow-blue-2x-total-height: 852px;
$arrow-blue-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-blue-2x: (468px, 306px, -468px, -306px, 36px, 40px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-blue@2x', );
$arrow-green-bottom-2x-name: 'arrow-green-bottom@2x';
$arrow-green-bottom-2x-x: 468px;
$arrow-green-bottom-2x-y: 350px;
$arrow-green-bottom-2x-offset-x: -468px;
$arrow-green-bottom-2x-offset-y: -350px;
$arrow-green-bottom-2x-width: 24px;
$arrow-green-bottom-2x-height: 22px;
$arrow-green-bottom-2x-total-width: 1014px;
$arrow-green-bottom-2x-total-height: 852px;
$arrow-green-bottom-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-green-bottom-2x: (468px, 350px, -468px, -350px, 24px, 22px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-green-bottom@2x', );
$arrow-green-right-2x-name: 'arrow-green-right@2x';
$arrow-green-right-2x-x: 472px;
$arrow-green-right-2x-y: 260px;
$arrow-green-right-2x-offset-x: -472px;
$arrow-green-right-2x-offset-y: -260px;
$arrow-green-right-2x-width: 22px;
$arrow-green-right-2x-height: 24px;
$arrow-green-right-2x-total-width: 1014px;
$arrow-green-right-2x-total-height: 852px;
$arrow-green-right-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-green-right-2x: (472px, 260px, -472px, -260px, 22px, 24px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-green-right@2x', );
$arrow-green-2x-name: 'arrow-green@2x';
$arrow-green-2x-x: 976px;
$arrow-green-2x-y: 702px;
$arrow-green-2x-offset-x: -976px;
$arrow-green-2x-offset-y: -702px;
$arrow-green-2x-width: 36px;
$arrow-green-2x-height: 40px;
$arrow-green-2x-total-width: 1014px;
$arrow-green-2x-total-height: 852px;
$arrow-green-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-green-2x: (976px, 702px, -976px, -702px, 36px, 40px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-green@2x', );
$arrow-pink-2x-name: 'arrow-pink@2x';
$arrow-pink-2x-x: 892px;
$arrow-pink-2x-y: 508px;
$arrow-pink-2x-offset-x: -892px;
$arrow-pink-2x-offset-y: -508px;
$arrow-pink-2x-width: 22px;
$arrow-pink-2x-height: 24px;
$arrow-pink-2x-total-width: 1014px;
$arrow-pink-2x-total-height: 852px;
$arrow-pink-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-pink-2x: (892px, 508px, -892px, -508px, 22px, 24px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-pink@2x', );
$arrow-white-menu-2x-name: 'arrow-white-menu@2x';
$arrow-white-menu-2x-x: 994px;
$arrow-white-menu-2x-y: 530px;
$arrow-white-menu-2x-offset-x: -994px;
$arrow-white-menu-2x-offset-y: -530px;
$arrow-white-menu-2x-width: 20px;
$arrow-white-menu-2x-height: 22px;
$arrow-white-menu-2x-total-width: 1014px;
$arrow-white-menu-2x-total-height: 852px;
$arrow-white-menu-2x-image: '/public/dist/img/sprite@2x.png';
$arrow-white-menu-2x: (994px, 530px, -994px, -530px, 20px, 22px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'arrow-white-menu@2x', );
$breadcrumb-2x-name: 'breadcrumb@2x';
$breadcrumb-2x-x: 892px;
$breadcrumb-2x-y: 536px;
$breadcrumb-2x-offset-x: -892px;
$breadcrumb-2x-offset-y: -536px;
$breadcrumb-2x-width: 22px;
$breadcrumb-2x-height: 24px;
$breadcrumb-2x-total-width: 1014px;
$breadcrumb-2x-total-height: 852px;
$breadcrumb-2x-image: '/public/dist/img/sprite@2x.png';
$breadcrumb-2x: (892px, 536px, -892px, -536px, 22px, 24px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'breadcrumb@2x', );
$bullet-hover-2x-name: 'bullet-hover@2x';
$bullet-hover-2x-x: 980px;
$bullet-hover-2x-y: 764px;
$bullet-hover-2x-offset-x: -980px;
$bullet-hover-2x-offset-y: -764px;
$bullet-hover-2x-width: 30px;
$bullet-hover-2x-height: 32px;
$bullet-hover-2x-total-width: 1014px;
$bullet-hover-2x-total-height: 852px;
$bullet-hover-2x-image: '/public/dist/img/sprite@2x.png';
$bullet-hover-2x: (980px, 764px, -980px, -764px, 30px, 32px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'bullet-hover@2x', );
$bullet-off-2x-name: 'bullet-off@2x';
$bullet-off-2x-x: 984px;
$bullet-off-2x-y: 590px;
$bullet-off-2x-offset-x: -984px;
$bullet-off-2x-offset-y: -590px;
$bullet-off-2x-width: 30px;
$bullet-off-2x-height: 32px;
$bullet-off-2x-total-width: 1014px;
$bullet-off-2x-total-height: 852px;
$bullet-off-2x-image: '/public/dist/img/sprite@2x.png';
$bullet-off-2x: (984px, 590px, -984px, -590px, 30px, 32px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'bullet-off@2x', );
$bullet-on-2x-name: 'bullet-on@2x';
$bullet-on-2x-x: 984px;
$bullet-on-2x-y: 646px;
$bullet-on-2x-offset-x: -984px;
$bullet-on-2x-offset-y: -646px;
$bullet-on-2x-width: 30px;
$bullet-on-2x-height: 32px;
$bullet-on-2x-total-width: 1014px;
$bullet-on-2x-total-height: 852px;
$bullet-on-2x-image: '/public/dist/img/sprite@2x.png';
$bullet-on-2x: (984px, 646px, -984px, -646px, 30px, 32px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'bullet-on@2x', );
$candidature-2x-name: 'candidature@2x';
$candidature-2x-x: 0px;
$candidature-2x-y: 260px;
$candidature-2x-offset-x: 0px;
$candidature-2x-offset-y: -260px;
$candidature-2x-width: 210px;
$candidature-2x-height: 194px;
$candidature-2x-total-width: 1014px;
$candidature-2x-total-height: 852px;
$candidature-2x-image: '/public/dist/img/sprite@2x.png';
$candidature-2x: (0px, 260px, 0px, -260px, 210px, 194px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'candidature@2x', );
$catching-up-2x-name: 'catchingUp@2x';
$catching-up-2x-x: 508px;
$catching-up-2x-y: 204px;
$catching-up-2x-offset-x: -508px;
$catching-up-2x-offset-y: -204px;
$catching-up-2x-width: 200px;
$catching-up-2x-height: 200px;
$catching-up-2x-total-width: 1014px;
$catching-up-2x-total-height: 852px;
$catching-up-2x-image: '/public/dist/img/sprite@2x.png';
$catching-up-2x: (508px, 204px, -508px, -204px, 200px, 200px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'catchingUp@2x', );
$close-grey-2x-name: 'close-grey@2x';
$close-grey-2x-x: 928px;
$close-grey-2x-y: 764px;
$close-grey-2x-offset-x: -928px;
$close-grey-2x-offset-y: -764px;
$close-grey-2x-width: 48px;
$close-grey-2x-height: 50px;
$close-grey-2x-total-width: 1014px;
$close-grey-2x-total-height: 852px;
$close-grey-2x-image: '/public/dist/img/sprite@2x.png';
$close-grey-2x: (928px, 764px, -928px, -764px, 48px, 50px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'close-grey@2x', );
$close-pink-2x-name: 'close-pink@2x';
$close-pink-2x-x: 428px;
$close-pink-2x-y: 260px;
$close-pink-2x-offset-x: -428px;
$close-pink-2x-offset-y: -260px;
$close-pink-2x-width: 40px;
$close-pink-2x-height: 42px;
$close-pink-2x-total-width: 1014px;
$close-pink-2x-total-height: 852px;
$close-pink-2x-image: '/public/dist/img/sprite@2x.png';
$close-pink-2x: (428px, 260px, -428px, -260px, 40px, 42px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'close-pink@2x', );
$contact-2x-name: 'contact@2x';
$contact-2x-x: 712px;
$contact-2x-y: 344px;
$contact-2x-offset-x: -712px;
$contact-2x-offset-y: -344px;
$contact-2x-width: 200px;
$contact-2x-height: 128px;
$contact-2x-total-width: 1014px;
$contact-2x-total-height: 852px;
$contact-2x-image: '/public/dist/img/sprite@2x.png';
$contact-2x: (712px, 344px, -712px, -344px, 200px, 128px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'contact@2x', );
$contactphone-2x-name: 'contactphone@2x';
$contactphone-2x-x: 132px;
$contactphone-2x-y: 642px;
$contactphone-2x-offset-x: -132px;
$contactphone-2x-offset-y: -642px;
$contactphone-2x-width: 90px;
$contactphone-2x-height: 92px;
$contactphone-2x-total-width: 1014px;
$contactphone-2x-total-height: 852px;
$contactphone-2x-image: '/public/dist/img/sprite@2x.png';
$contactphone-2x: (132px, 642px, -132px, -642px, 90px, 92px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'contactphone@2x', );
$date-2x-name: 'date@2x';
$date-2x-x: 214px;
$date-2x-y: 458px;
$date-2x-offset-x: -214px;
$date-2x-offset-y: -458px;
$date-2x-width: 210px;
$date-2x-height: 172px;
$date-2x-total-width: 1014px;
$date-2x-total-height: 852px;
$date-2x-image: '/public/dist/img/sprite@2x.png';
$date-2x: (214px, 458px, -214px, -458px, 210px, 172px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'date@2x', );
$doc-2x-name: 'doc@2x';
$doc-2x-x: 112px;
$doc-2x-y: 744px;
$doc-2x-offset-x: -112px;
$doc-2x-offset-y: -744px;
$doc-2x-width: 100px;
$doc-2x-height: 100px;
$doc-2x-total-width: 1014px;
$doc-2x-total-height: 852px;
$doc-2x-image: '/public/dist/img/sprite@2x.png';
$doc-2x: (112px, 744px, -112px, -744px, 100px, 100px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'doc@2x', );
$download-green-2x-name: 'download-green@2x';
$download-green-2x-x: 814px;
$download-green-2x-y: 586px;
$download-green-2x-offset-x: -814px;
$download-green-2x-offset-y: -586px;
$download-green-2x-width: 46px;
$download-green-2x-height: 46px;
$download-green-2x-total-width: 1014px;
$download-green-2x-total-height: 852px;
$download-green-2x-image: '/public/dist/img/sprite@2x.png';
$download-green-2x: (814px, 586px, -814px, -586px, 46px, 46px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'download-green@2x', );
$download-pink-2x-name: 'download-pink@2x';
$download-pink-2x-x: 864px;
$download-pink-2x-y: 586px;
$download-pink-2x-offset-x: -864px;
$download-pink-2x-offset-y: -586px;
$download-pink-2x-width: 46px;
$download-pink-2x-height: 46px;
$download-pink-2x-total-width: 1014px;
$download-pink-2x-total-height: 852px;
$download-pink-2x-image: '/public/dist/img/sprite@2x.png';
$download-pink-2x: (864px, 586px, -864px, -586px, 46px, 46px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'download-pink@2x', );
$download-2x-name: 'download@2x';
$download-2x-x: 0px;
$download-2x-y: 458px;
$download-2x-offset-x: 0px;
$download-2x-offset-y: -458px;
$download-2x-width: 210px;
$download-2x-height: 180px;
$download-2x-total-width: 1014px;
$download-2x-total-height: 852px;
$download-2x-image: '/public/dist/img/sprite@2x.png';
$download-2x: (0px, 458px, 0px, -458px, 210px, 180px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'download@2x', );
$event-2x-name: 'event@2x';
$event-2x-x: 428px;
$event-2x-y: 458px;
$event-2x-offset-x: -428px;
$event-2x-offset-y: -458px;
$event-2x-width: 212px;
$event-2x-height: 168px;
$event-2x-total-width: 1014px;
$event-2x-total-height: 852px;
$event-2x-image: '/public/dist/img/sprite@2x.png';
$event-2x: (428px, 458px, -428px, -458px, 212px, 168px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'event@2x', );
$eye-blue-2x-name: 'eye-blue@2x';
$eye-blue-2x-x: 654px;
$eye-blue-2x-y: 408px;
$eye-blue-2x-offset-x: -654px;
$eye-blue-2x-offset-y: -408px;
$eye-blue-2x-width: 46px;
$eye-blue-2x-height: 30px;
$eye-blue-2x-total-width: 1014px;
$eye-blue-2x-total-height: 852px;
$eye-blue-2x-image: '/public/dist/img/sprite@2x.png';
$eye-blue-2x: (654px, 408px, -654px, -408px, 46px, 30px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'eye-blue@2x', );
$eye-pink-2x-name: 'eye-pink@2x';
$eye-pink-2x-x: 928px;
$eye-pink-2x-y: 818px;
$eye-pink-2x-offset-x: -928px;
$eye-pink-2x-offset-y: -818px;
$eye-pink-2x-width: 46px;
$eye-pink-2x-height: 30px;
$eye-pink-2x-total-width: 1014px;
$eye-pink-2x-total-height: 852px;
$eye-pink-2x-image: '/public/dist/img/sprite@2x.png';
$eye-pink-2x: (928px, 818px, -928px, -818px, 46px, 30px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'eye-pink@2x', );
$faq-right-2x-name: 'faq-right@2x';
$faq-right-2x-x: 892px;
$faq-right-2x-y: 564px;
$faq-right-2x-offset-x: -892px;
$faq-right-2x-offset-y: -564px;
$faq-right-2x-width: 26px;
$faq-right-2x-height: 18px;
$faq-right-2x-total-width: 1014px;
$faq-right-2x-total-height: 852px;
$faq-right-2x-image: '/public/dist/img/sprite@2x.png';
$faq-right-2x: (892px, 564px, -892px, -564px, 26px, 18px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'faq-right@2x', );
$faq-up-2x-name: 'faq-up@2x';
$faq-up-2x-x: 428px;
$faq-up-2x-y: 394px;
$faq-up-2x-offset-x: -428px;
$faq-up-2x-offset-y: -394px;
$faq-up-2x-width: 22px;
$faq-up-2x-height: 22px;
$faq-up-2x-total-width: 1014px;
$faq-up-2x-total-height: 852px;
$faq-up-2x-image: '/public/dist/img/sprite@2x.png';
$faq-up-2x: (428px, 394px, -428px, -394px, 22px, 22px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'faq-up@2x', );
$fast-link-blue-dark-2x-name: 'fast-link-blue-dark@2x';
$fast-link-blue-dark-2x-x: 0px;
$fast-link-blue-dark-2x-y: 0px;
$fast-link-blue-dark-2x-offset-x: 0px;
$fast-link-blue-dark-2x-offset-y: 0px;
$fast-link-blue-dark-2x-width: 166px;
$fast-link-blue-dark-2x-height: 256px;
$fast-link-blue-dark-2x-total-width: 1014px;
$fast-link-blue-dark-2x-total-height: 852px;
$fast-link-blue-dark-2x-image: '/public/dist/img/sprite@2x.png';
$fast-link-blue-dark-2x: (0px, 0px, 0px, 0px, 166px, 256px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'fast-link-blue-dark@2x', );
$fast-link-blue-light-2x-name: 'fast-link-blue-light@2x';
$fast-link-blue-light-2x-x: 340px;
$fast-link-blue-light-2x-y: 0px;
$fast-link-blue-light-2x-offset-x: -340px;
$fast-link-blue-light-2x-offset-y: 0px;
$fast-link-blue-light-2x-width: 164px;
$fast-link-blue-light-2x-height: 254px;
$fast-link-blue-light-2x-total-width: 1014px;
$fast-link-blue-light-2x-total-height: 852px;
$fast-link-blue-light-2x-image: '/public/dist/img/sprite@2x.png';
$fast-link-blue-light-2x: (340px, 0px, -340px, 0px, 164px, 254px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'fast-link-blue-light@2x', );
$fast-link-green-2x-name: 'fast-link-green@2x';
$fast-link-green-2x-x: 170px;
$fast-link-green-2x-y: 0px;
$fast-link-green-2x-offset-x: -170px;
$fast-link-green-2x-offset-y: 0px;
$fast-link-green-2x-width: 166px;
$fast-link-green-2x-height: 256px;
$fast-link-green-2x-total-width: 1014px;
$fast-link-green-2x-total-height: 852px;
$fast-link-green-2x-image: '/public/dist/img/sprite@2x.png';
$fast-link-green-2x: (170px, 0px, -170px, 0px, 166px, 256px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'fast-link-green@2x', );
$fb-2x-name: 'fb@2x';
$fb-2x-x: 928px;
$fb-2x-y: 358px;
$fb-2x-offset-x: -928px;
$fb-2x-offset-y: -358px;
$fb-2x-width: 80px;
$fb-2x-height: 80px;
$fb-2x-total-width: 1014px;
$fb-2x-total-height: 852px;
$fb-2x-image: '/public/dist/img/sprite@2x.png';
$fb-2x: (928px, 358px, -928px, -358px, 80px, 80px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'fb@2x', );
$file-2x-name: 'file@2x';
$file-2x-x: 928px;
$file-2x-y: 442px;
$file-2x-offset-x: -928px;
$file-2x-offset-y: -442px;
$file-2x-width: 66px;
$file-2x-height: 84px;
$file-2x-total-width: 1014px;
$file-2x-total-height: 852px;
$file-2x-image: '/public/dist/img/sprite@2x.png';
$file-2x: (928px, 442px, -928px, -442px, 66px, 84px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'file@2x', );
$home-2x-name: 'home@2x';
$home-2x-x: 928px;
$home-2x-y: 530px;
$home-2x-offset-x: -928px;
$home-2x-offset-y: -530px;
$home-2x-width: 62px;
$home-2x-height: 56px;
$home-2x-total-width: 1014px;
$home-2x-total-height: 852px;
$home-2x-image: '/public/dist/img/sprite@2x.png';
$home-2x: (928px, 530px, -928px, -530px, 62px, 56px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'home@2x', );
$image-2x-name: 'image@2x';
$image-2x-x: 712px;
$image-2x-y: 0px;
$image-2x-offset-x: -712px;
$image-2x-offset-y: 0px;
$image-2x-width: 212px;
$image-2x-height: 168px;
$image-2x-total-width: 1014px;
$image-2x-total-height: 852px;
$image-2x-image: '/public/dist/img/sprite@2x.png';
$image-2x: (712px, 0px, -712px, 0px, 212px, 168px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'image@2x', );
$instagram-2x-name: 'instagram@2x';
$instagram-2x-x: 928px;
$instagram-2x-y: 274px;
$instagram-2x-offset-x: -928px;
$instagram-2x-offset-y: -274px;
$instagram-2x-width: 80px;
$instagram-2x-height: 80px;
$instagram-2x-total-width: 1014px;
$instagram-2x-total-height: 852px;
$instagram-2x-image: '/public/dist/img/sprite@2x.png';
$instagram-2x: (928px, 274px, -928px, -274px, 80px, 80px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'instagram@2x', );
$job-2x-name: 'job@2x';
$job-2x-x: 508px;
$job-2x-y: 0px;
$job-2x-offset-x: -508px;
$job-2x-offset-y: 0px;
$job-2x-width: 200px;
$job-2x-height: 200px;
$job-2x-total-width: 1014px;
$job-2x-total-height: 852px;
$job-2x-image: '/public/dist/img/sprite@2x.png';
$job-2x: (508px, 0px, -508px, 0px, 200px, 200px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'job@2x', );
$larrow-white-2x-name: 'larrow-white@2x';
$larrow-white-2x-x: 428px;
$larrow-white-2x-y: 350px;
$larrow-white-2x-offset-x: -428px;
$larrow-white-2x-offset-y: -350px;
$larrow-white-2x-width: 36px;
$larrow-white-2x-height: 40px;
$larrow-white-2x-total-width: 1014px;
$larrow-white-2x-total-height: 852px;
$larrow-white-2x-image: '/public/dist/img/sprite@2x.png';
$larrow-white-2x: (428px, 350px, -428px, -350px, 36px, 40px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'larrow-white@2x', );
$linkedin-2x-name: 'linkedin@2x';
$linkedin-2x-x: 928px;
$linkedin-2x-y: 106px;
$linkedin-2x-offset-x: -928px;
$linkedin-2x-offset-y: -106px;
$linkedin-2x-width: 80px;
$linkedin-2x-height: 80px;
$linkedin-2x-total-width: 1014px;
$linkedin-2x-total-height: 852px;
$linkedin-2x-image: '/public/dist/img/sprite@2x.png';
$linkedin-2x: (928px, 106px, -928px, -106px, 80px, 80px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'linkedin@2x', );
$loupe-2x-name: 'loupe@2x';
$loupe-2x-x: 762px;
$loupe-2x-y: 586px;
$loupe-2x-offset-x: -762px;
$loupe-2x-offset-y: -586px;
$loupe-2x-width: 48px;
$loupe-2x-height: 48px;
$loupe-2x-total-width: 1014px;
$loupe-2x-total-height: 852px;
$loupe-2x-image: '/public/dist/img/sprite@2x.png';
$loupe-2x: (762px, 586px, -762px, -586px, 48px, 48px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'loupe@2x', );
$map-2x-name: 'map@2x';
$map-2x-x: 928px;
$map-2x-y: 702px;
$map-2x-offset-x: -928px;
$map-2x-offset-y: -702px;
$map-2x-width: 44px;
$map-2x-height: 58px;
$map-2x-total-width: 1014px;
$map-2x-total-height: 852px;
$map-2x-image: '/public/dist/img/sprite@2x.png';
$map-2x: (928px, 702px, -928px, -702px, 44px, 58px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'map@2x', );
$menu-left-2x-name: 'menu-left@2x';
$menu-left-2x-x: 892px;
$menu-left-2x-y: 476px;
$menu-left-2x-offset-x: -892px;
$menu-left-2x-offset-y: -476px;
$menu-left-2x-width: 26px;
$menu-left-2x-height: 28px;
$menu-left-2x-total-width: 1014px;
$menu-left-2x-total-height: 852px;
$menu-left-2x-image: '/public/dist/img/sprite@2x.png';
$menu-left-2x: (892px, 476px, -892px, -476px, 26px, 28px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'menu-left@2x', );
$menu-right-2x-name: 'menu-right@2x';
$menu-right-2x-x: 978px;
$menu-right-2x-y: 818px;
$menu-right-2x-offset-x: -978px;
$menu-right-2x-offset-y: -818px;
$menu-right-2x-width: 26px;
$menu-right-2x-height: 28px;
$menu-right-2x-total-width: 1014px;
$menu-right-2x-total-height: 852px;
$menu-right-2x-image: '/public/dist/img/sprite@2x.png';
$menu-right-2x: (978px, 818px, -978px, -818px, 26px, 28px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'menu-right@2x', );
$more-2x-name: 'more@2x';
$more-2x-x: 928px;
$more-2x-y: 646px;
$more-2x-offset-x: -928px;
$more-2x-offset-y: -646px;
$more-2x-width: 52px;
$more-2x-height: 52px;
$more-2x-total-width: 1014px;
$more-2x-total-height: 852px;
$more-2x-image: '/public/dist/img/sprite@2x.png';
$more-2x: (928px, 646px, -928px, -646px, 52px, 52px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'more@2x', );
$museum-2x-name: 'museum@2x';
$museum-2x-x: 712px;
$museum-2x-y: 476px;
$museum-2x-offset-x: -712px;
$museum-2x-offset-y: -476px;
$museum-2x-width: 176px;
$museum-2x-height: 106px;
$museum-2x-total-width: 1014px;
$museum-2x-total-height: 852px;
$museum-2x-image: '/public/dist/img/sprite@2x.png';
$museum-2x: (712px, 476px, -712px, -476px, 176px, 106px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'museum@2x', );
$news-2x-name: 'news@2x';
$news-2x-x: 0px;
$news-2x-y: 642px;
$news-2x-offset-x: 0px;
$news-2x-offset-y: -642px;
$news-2x-width: 128px;
$news-2x-height: 98px;
$news-2x-total-width: 1014px;
$news-2x-total-height: 852px;
$news-2x-image: '/public/dist/img/sprite@2x.png';
$news-2x: (0px, 642px, 0px, -642px, 128px, 98px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'news@2x', );
$next-2x-name: 'next@2x';
$next-2x-x: 928px;
$next-2x-y: 590px;
$next-2x-offset-x: -928px;
$next-2x-offset-y: -590px;
$next-2x-width: 52px;
$next-2x-height: 52px;
$next-2x-total-width: 1014px;
$next-2x-total-height: 852px;
$next-2x-image: '/public/dist/img/sprite@2x.png';
$next-2x: (928px, 590px, -928px, -590px, 52px, 52px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'next@2x', );
$note-2x-name: 'note@2x';
$note-2x-x: 712px;
$note-2x-y: 172px;
$note-2x-offset-x: -712px;
$note-2x-offset-y: -172px;
$note-2x-width: 212px;
$note-2x-height: 168px;
$note-2x-total-width: 1014px;
$note-2x-total-height: 852px;
$note-2x-image: '/public/dist/img/sprite@2x.png';
$note-2x: (712px, 172px, -712px, -172px, 212px, 168px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'note@2x', );
$pdf-2x-name: 'pdf@2x';
$pdf-2x-x: 712px;
$pdf-2x-y: 586px;
$pdf-2x-offset-x: -712px;
$pdf-2x-offset-y: -586px;
$pdf-2x-width: 46px;
$pdf-2x-height: 52px;
$pdf-2x-total-width: 1014px;
$pdf-2x-total-height: 852px;
$pdf-2x-image: '/public/dist/img/sprite@2x.png';
$pdf-2x: (712px, 586px, -712px, -586px, 46px, 52px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'pdf@2x', );
$pinterest-2x-name: 'pinterest@2x';
$pinterest-2x-x: 928px;
$pinterest-2x-y: 190px;
$pinterest-2x-offset-x: -928px;
$pinterest-2x-offset-y: -190px;
$pinterest-2x-width: 80px;
$pinterest-2x-height: 80px;
$pinterest-2x-total-width: 1014px;
$pinterest-2x-total-height: 852px;
$pinterest-2x-image: '/public/dist/img/sprite@2x.png';
$pinterest-2x: (928px, 190px, -928px, -190px, 80px, 80px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'pinterest@2x', );
$quotes-2x-name: 'quotes@2x';
$quotes-2x-x: 508px;
$quotes-2x-y: 408px;
$quotes-2x-offset-x: -508px;
$quotes-2x-offset-y: -408px;
$quotes-2x-width: 48px;
$quotes-2x-height: 40px;
$quotes-2x-total-width: 1014px;
$quotes-2x-total-height: 852px;
$quotes-2x-image: '/public/dist/img/sprite@2x.png';
$quotes-2x: (508px, 408px, -508px, -408px, 48px, 40px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'quotes@2x', );
$rarrow-white-2x-name: 'rarrow-white@2x';
$rarrow-white-2x-x: 428px;
$rarrow-white-2x-y: 306px;
$rarrow-white-2x-offset-x: -428px;
$rarrow-white-2x-offset-y: -306px;
$rarrow-white-2x-width: 36px;
$rarrow-white-2x-height: 40px;
$rarrow-white-2x-total-width: 1014px;
$rarrow-white-2x-total-height: 852px;
$rarrow-white-2x-image: '/public/dist/img/sprite@2x.png';
$rarrow-white-2x: (428px, 306px, -428px, -306px, 36px, 40px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'rarrow-white@2x', );
$tarrow-blue-2x-name: 'tarrow-blue@2x';
$tarrow-blue-2x-x: 610px;
$tarrow-blue-2x-y: 408px;
$tarrow-blue-2x-offset-x: -610px;
$tarrow-blue-2x-offset-y: -408px;
$tarrow-blue-2x-width: 40px;
$tarrow-blue-2x-height: 36px;
$tarrow-blue-2x-total-width: 1014px;
$tarrow-blue-2x-total-height: 852px;
$tarrow-blue-2x-image: '/public/dist/img/sprite@2x.png';
$tarrow-blue-2x: (610px, 408px, -610px, -408px, 40px, 36px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'tarrow-blue@2x', );
$triangle-2x-name: 'triangle@2x';
$triangle-2x-x: 0px;
$triangle-2x-y: 744px;
$triangle-2x-offset-x: 0px;
$triangle-2x-offset-y: -744px;
$triangle-2x-width: 108px;
$triangle-2x-height: 108px;
$triangle-2x-total-width: 1014px;
$triangle-2x-total-height: 852px;
$triangle-2x-image: '/public/dist/img/sprite@2x.png';
$triangle-2x: (0px, 744px, 0px, -744px, 108px, 108px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'triangle@2x', );
$users-2x-name: 'users@2x';
$users-2x-x: 928px;
$users-2x-y: 0px;
$users-2x-offset-x: -928px;
$users-2x-offset-y: 0px;
$users-2x-width: 86px;
$users-2x-height: 102px;
$users-2x-total-width: 1014px;
$users-2x-total-height: 852px;
$users-2x-image: '/public/dist/img/sprite@2x.png';
$users-2x: (928px, 0px, -928px, 0px, 86px, 102px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'users@2x', );
$zoom-2x-name: 'zoom@2x';
$zoom-2x-x: 560px;
$zoom-2x-y: 408px;
$zoom-2x-offset-x: -560px;
$zoom-2x-offset-y: -408px;
$zoom-2x-width: 46px;
$zoom-2x-height: 38px;
$zoom-2x-total-width: 1014px;
$zoom-2x-total-height: 852px;
$zoom-2x-image: '/public/dist/img/sprite@2x.png';
$zoom-2x: (560px, 408px, -560px, -408px, 46px, 38px, 1014px, 852px, '/public/dist/img/sprite@2x.png', 'zoom@2x', );
$spritesheet-width: 507px;
$spritesheet-height: 426px;
$spritesheet-image: '/public/dist/img/sprite.png';
$spritesheet-sprites: ($agenda, $arrow-blue, $arrow-green-bottom, $arrow-green-right, $arrow-green, $arrow-pink, $arrow-white-menu, $breadcrumb, $bullet-hover, $bullet-off, $bullet-on, $candidature, $catching-up, $close-grey, $close-pink, $contact, $contactphone, $date, $doc, $download-green, $download-pink, $download, $event, $eye-blue, $eye-pink, $faq-right, $faq-up, $fast-link-blue-dark, $fast-link-blue-light, $fast-link-green, $fb, $file, $home, $image, $instagram, $job, $larrow-white, $linkedin, $loupe, $map, $menu-left, $menu-right, $more, $museum, $news, $next, $note, $pdf, $pinterest, $quotes, $rarrow-white, $tarrow-blue, $triangle, $users, $zoom, );
$spritesheet: (507px, 426px, '/public/dist/img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 1014px;
$retina-spritesheet-height: 852px;
$retina-spritesheet-image: '/public/dist/img/sprite@2x.png';
$retina-spritesheet-sprites: ($agenda-2x, $arrow-blue-2x, $arrow-green-bottom-2x, $arrow-green-right-2x, $arrow-green-2x, $arrow-pink-2x, $arrow-white-menu-2x, $breadcrumb-2x, $bullet-hover-2x, $bullet-off-2x, $bullet-on-2x, $candidature-2x, $catching-up-2x, $close-grey-2x, $close-pink-2x, $contact-2x, $contactphone-2x, $date-2x, $doc-2x, $download-green-2x, $download-pink-2x, $download-2x, $event-2x, $eye-blue-2x, $eye-pink-2x, $faq-right-2x, $faq-up-2x, $fast-link-blue-dark-2x, $fast-link-blue-light-2x, $fast-link-green-2x, $fb-2x, $file-2x, $home-2x, $image-2x, $instagram-2x, $job-2x, $larrow-white-2x, $linkedin-2x, $loupe-2x, $map-2x, $menu-left-2x, $menu-right-2x, $more-2x, $museum-2x, $news-2x, $next-2x, $note-2x, $pdf-2x, $pinterest-2x, $quotes-2x, $rarrow-white-2x, $tarrow-blue-2x, $triangle-2x, $users-2x, $zoom-2x, );
$retina-spritesheet: (1014px, 852px, '/public/dist/img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$agenda-group-name: 'agenda';
$agenda-group: ('agenda', $agenda, $agenda-2x, );
$arrow-blue-group-name: 'arrow-blue';
$arrow-blue-group: ('arrow-blue', $arrow-blue, $arrow-blue-2x, );
$arrow-green-bottom-group-name: 'arrow-green-bottom';
$arrow-green-bottom-group: ('arrow-green-bottom', $arrow-green-bottom, $arrow-green-bottom-2x, );
$arrow-green-right-group-name: 'arrow-green-right';
$arrow-green-right-group: ('arrow-green-right', $arrow-green-right, $arrow-green-right-2x, );
$arrow-green-group-name: 'arrow-green';
$arrow-green-group: ('arrow-green', $arrow-green, $arrow-green-2x, );
$arrow-pink-group-name: 'arrow-pink';
$arrow-pink-group: ('arrow-pink', $arrow-pink, $arrow-pink-2x, );
$arrow-white-menu-group-name: 'arrow-white-menu';
$arrow-white-menu-group: ('arrow-white-menu', $arrow-white-menu, $arrow-white-menu-2x, );
$breadcrumb-group-name: 'breadcrumb';
$breadcrumb-group: ('breadcrumb', $breadcrumb, $breadcrumb-2x, );
$bullet-hover-group-name: 'bullet-hover';
$bullet-hover-group: ('bullet-hover', $bullet-hover, $bullet-hover-2x, );
$bullet-off-group-name: 'bullet-off';
$bullet-off-group: ('bullet-off', $bullet-off, $bullet-off-2x, );
$bullet-on-group-name: 'bullet-on';
$bullet-on-group: ('bullet-on', $bullet-on, $bullet-on-2x, );
$candidature-group-name: 'candidature';
$candidature-group: ('candidature', $candidature, $candidature-2x, );
$catching-up-group-name: 'catchingUp';
$catching-up-group: ('catchingUp', $catching-up, $catching-up-2x, );
$close-grey-group-name: 'close-grey';
$close-grey-group: ('close-grey', $close-grey, $close-grey-2x, );
$close-pink-group-name: 'close-pink';
$close-pink-group: ('close-pink', $close-pink, $close-pink-2x, );
$contact-group-name: 'contact';
$contact-group: ('contact', $contact, $contact-2x, );
$contactphone-group-name: 'contactphone';
$contactphone-group: ('contactphone', $contactphone, $contactphone-2x, );
$date-group-name: 'date';
$date-group: ('date', $date, $date-2x, );
$doc-group-name: 'doc';
$doc-group: ('doc', $doc, $doc-2x, );
$download-green-group-name: 'download-green';
$download-green-group: ('download-green', $download-green, $download-green-2x, );
$download-pink-group-name: 'download-pink';
$download-pink-group: ('download-pink', $download-pink, $download-pink-2x, );
$download-group-name: 'download';
$download-group: ('download', $download, $download-2x, );
$event-group-name: 'event';
$event-group: ('event', $event, $event-2x, );
$eye-blue-group-name: 'eye-blue';
$eye-blue-group: ('eye-blue', $eye-blue, $eye-blue-2x, );
$eye-pink-group-name: 'eye-pink';
$eye-pink-group: ('eye-pink', $eye-pink, $eye-pink-2x, );
$faq-right-group-name: 'faq-right';
$faq-right-group: ('faq-right', $faq-right, $faq-right-2x, );
$faq-up-group-name: 'faq-up';
$faq-up-group: ('faq-up', $faq-up, $faq-up-2x, );
$fast-link-blue-dark-group-name: 'fast-link-blue-dark';
$fast-link-blue-dark-group: ('fast-link-blue-dark', $fast-link-blue-dark, $fast-link-blue-dark-2x, );
$fast-link-blue-light-group-name: 'fast-link-blue-light';
$fast-link-blue-light-group: ('fast-link-blue-light', $fast-link-blue-light, $fast-link-blue-light-2x, );
$fast-link-green-group-name: 'fast-link-green';
$fast-link-green-group: ('fast-link-green', $fast-link-green, $fast-link-green-2x, );
$fb-group-name: 'fb';
$fb-group: ('fb', $fb, $fb-2x, );
$file-group-name: 'file';
$file-group: ('file', $file, $file-2x, );
$home-group-name: 'home';
$home-group: ('home', $home, $home-2x, );
$image-group-name: 'image';
$image-group: ('image', $image, $image-2x, );
$instagram-group-name: 'instagram';
$instagram-group: ('instagram', $instagram, $instagram-2x, );
$job-group-name: 'job';
$job-group: ('job', $job, $job-2x, );
$larrow-white-group-name: 'larrow-white';
$larrow-white-group: ('larrow-white', $larrow-white, $larrow-white-2x, );
$linkedin-group-name: 'linkedin';
$linkedin-group: ('linkedin', $linkedin, $linkedin-2x, );
$loupe-group-name: 'loupe';
$loupe-group: ('loupe', $loupe, $loupe-2x, );
$map-group-name: 'map';
$map-group: ('map', $map, $map-2x, );
$menu-left-group-name: 'menu-left';
$menu-left-group: ('menu-left', $menu-left, $menu-left-2x, );
$menu-right-group-name: 'menu-right';
$menu-right-group: ('menu-right', $menu-right, $menu-right-2x, );
$more-group-name: 'more';
$more-group: ('more', $more, $more-2x, );
$museum-group-name: 'museum';
$museum-group: ('museum', $museum, $museum-2x, );
$news-group-name: 'news';
$news-group: ('news', $news, $news-2x, );
$next-group-name: 'next';
$next-group: ('next', $next, $next-2x, );
$note-group-name: 'note';
$note-group: ('note', $note, $note-2x, );
$pdf-group-name: 'pdf';
$pdf-group: ('pdf', $pdf, $pdf-2x, );
$pinterest-group-name: 'pinterest';
$pinterest-group: ('pinterest', $pinterest, $pinterest-2x, );
$quotes-group-name: 'quotes';
$quotes-group: ('quotes', $quotes, $quotes-2x, );
$rarrow-white-group-name: 'rarrow-white';
$rarrow-white-group: ('rarrow-white', $rarrow-white, $rarrow-white-2x, );
$tarrow-blue-group-name: 'tarrow-blue';
$tarrow-blue-group: ('tarrow-blue', $tarrow-blue, $tarrow-blue-2x, );
$triangle-group-name: 'triangle';
$triangle-group: ('triangle', $triangle, $triangle-2x, );
$users-group-name: 'users';
$users-group: ('users', $users, $users-2x, );
$zoom-group-name: 'zoom';
$zoom-group: ('zoom', $zoom, $zoom-2x, );
$retina-groups: ($agenda-group, $arrow-blue-group, $arrow-green-bottom-group, $arrow-green-right-group, $arrow-green-group, $arrow-pink-group, $arrow-white-menu-group, $breadcrumb-group, $bullet-hover-group, $bullet-off-group, $bullet-on-group, $candidature-group, $catching-up-group, $close-grey-group, $close-pink-group, $contact-group, $contactphone-group, $date-group, $doc-group, $download-green-group, $download-pink-group, $download-group, $event-group, $eye-blue-group, $eye-pink-group, $faq-right-group, $faq-up-group, $fast-link-blue-dark-group, $fast-link-blue-light-group, $fast-link-green-group, $fb-group, $file-group, $home-group, $image-group, $instagram-group, $job-group, $larrow-white-group, $linkedin-group, $loupe-group, $map-group, $menu-left-group, $menu-right-group, $more-group, $museum-group, $news-group, $next-group, $note-group, $pdf-group, $pinterest-group, $quotes-group, $rarrow-white-group, $tarrow-blue-group, $triangle-group, $users-group, $zoom-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
