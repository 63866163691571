.projects {
  h2 {
    color: $blue;
    font-family: $montserrat;
    text-transform: uppercase;
  }

  .h3-like {
    margin-top: 40px;
  }


  ul {
    li {
      width: 100%;
      margin: 15px 0;
      display: inline-block;
      background-color: $green;

      h3 {
        margin-bottom: 10px;
      }

      a {
        padding: 20px 10px 35px;
        position: relative;
        display: block;
        height: 100%;

        .link {
          color: white;
          position: absolute;
          bottom: 10px;
          text-align: center;
          left: 0;
          right: 0;
        }

      }
    }
  }


  .album {
    margin: -10px;

    a {
      display: inline-block;
      width: 50%;
      padding: 8px 10px;

      img {
        margin: 0;
      }
    }
  }


}
