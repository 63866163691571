.select2-container {
  li {
    font-family: $montserrat;
    font-size: 1.2rem;
  }
}

.left-bg {
  background-color: #efeff0;
  padding-top: 20px;
  padding-bottom: 20px;

  .avatar {
    text-align: center;
    padding-top: 0;
    width: auto;

    img {
      border: 3px solid $green;
      border-radius: 100%;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 15px;
    color: $green;
    font-family: $montserrat;
    font-weight: 700;
    font-size: 1.8rem;
  }

  .hidefilter {
    display: none;
  }

  li {
    font-family: $montserrat;
    font-size: 1.2rem;
  }

  h3 {
    margin: 15px 0 5px;
    font-size: 1.5rem;
  }

  select, .select2 {
    width: 100% !important;
  }

  input {
    width: 100%;
    padding: 5px;
    font-family: $montserrat;
  }
}
