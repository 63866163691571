.top {
  display: block;
  margin: 20px auto;
  text-align: center;
  padding: 10px;
  font-family: $marcellus;
  color: $blue;
  font-size: 1.4rem;

  .icon {
    display: block;
    margin: 5px auto;
  }
}

.footer {
  margin-top: 15px;

  .handicap {
    img {
      margin-top: 20px;
      width: 60px;
      height: 60px;
    }

    p {
      margin-top: 5px !important;
      line-height: 1.5 !important;
    }
  }

  .footer-top-social {
    display: flex;
    justify-content: space-between;
    width: 200px;
    position: absolute;
    top: -20px;
    right: 25px;
    z-index: 100;

    a .icon {
      text-indent: -3000px;
    }
  }

  background-color: $green-dark;
  width: 100%;
  .footer-top {
    display: none;
  }
  .footer-contact {
    position: relative;
    text-align: center;
    h2 {
      padding: 25px 5px 15px;
      font-family: $marcellus;
      font-size: 1.8rem;
      color: $blue-dark;
    }

    p {
      font-family: $montserrat;
      color: $blue-dark;
      line-height: 2.3rem;
    }

    a.btn {
      display: inline-block;
      padding: 10px 5px 25px;
      color: white;
      font-size: 2rem;

      & .icon {
        margin-left: 10px;
        position: relative;
        top: 4px;
      }
    }
  }

  .footer-bottom {
    position: relative;
    background-color: $blue;
    padding: 6px;

    .fb {
      display: none;
    }

    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      a {
        font-size: 1.1rem;
        display: inline-block;
        margin: 0;
        padding: 6px;
        color: white;
        font-family: $montserrat;
      }
    }
  }
}
