.clipboard {
  margin-top: 10px;
  width: 100%;
  display: flex;
  input {
    height: 30px;
    width: 100%;
  }
  button {
    line-height: 30px;
    height: 30px;
    width: 30px;
    position: relative;
    left: -1px;
    img {
      position: relative;
      left: 1px;
    }
  }
}
