
/*
.anav-mov {
  transition: transform 1 ease;
  transform: translate(0, 0);
  &.anav-moved {
    transform: translate($nav-width, 0);
  }
}
*/


.body {
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  background-color: #ffffff;
  z-index: 200;
}

.anav-bg {
  text-indent: -9999px;
  position: fixed;
  top: 49px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #242424;
  z-index: 300;
  opacity: 0.5;
  display: none;
}

.anav {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  width: $nav-width;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 2px solid $grey9;

  ul {
    width: $nav-width;
    height: 100%;
    background-color: white;

    li {
      background-color: $grey-light;
      border-bottom: 2px solid $green;
      font-family: $montserrat;
      font-weight: 700;
      color: $blue-dark;

      & a {
        display: inline-block;
        color: $blue-dark;
        text-decoration: none;
        width: 100%;
        padding: 10px 6px;
        font-size: 1.4rem;
        padding-left: 30px;
        padding-right: 35px;
        position: relative;

        & > .icon-menu-right {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.navhead {
        line-height: 1.6rem;
        text-align: center;
        border-bottom: 1px solid $greyc;
        font-size: 1.6rem;
        width: $nav-width;
        text-transform: uppercase;

        &.navhead-menu, a {
          padding: 22px 15px;
        }

        .icon-menu-left {
          margin: 0 10px 0 0;
          position: relative;
          top: 2px;
        }

        &.navhead-sub-menu {
          background-color: $green;

          a {
            color: #ffffff;
          }
        }
      }

      &.active > a {
        color: $green;
      }
    }
  }

  & > ul {

    position: relative;
    left: 0;

    ul {
      position: absolute;
      top: 0;
      left: $nav-width;

      ul {
        left: $nav-width;

        ul {
          transform: translateX($nav-width);
        }
      }
    }
  }
}
