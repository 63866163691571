.page {
  .documents {
    .unities {
      display: flex;
      justify-content: center;
      a {
        display: inline-block;
        border: 1px solid $green;
        padding: 5px;
        margin: 5px;
        text-decoration: none;
        font-weight: bold;

        &.active {
          background-color: $green;
          color: white;
        }

        &:hover {
          border: 3px solid $green;
          padding: 3px;
          color: $green;
        }
      }
    }

    .left-bg {
      margin-bottom: 15px;
      h2 {
        text-align: center;
        margin-bottom: 15px;
        color: $green;
        font-family: $montserrat;
        font-weight: 700;
        font-size: 1.8rem;
        text-transform: none;
      }
    }
  }
}
