
.cards {

  .card {
    display: block;
    background-image: url("/public/img/card.jpg");
    background-size: cover;
    position: relative;
    margin: 20px 0;
    padding: 0;

    &.xs-blue a {
      background-color: rgba($blue, 0.8);
      .more {
        background-color: $blue;
      }
    }
    &.xs-green a {
      background-color: rgba(#8aa016, 0.8);
      .more {
        background-color: $green;
      }
    }

    a {
      display: block;
      padding: 15px 15px 40px;


      h3, p {
        color: #fff;
        text-align: center;
      }

      h3 {
        margin: 0 0 10px;
        font-family: $montserrat;
        font-weight: 700;
      }

      p {
        margin-bottom: 5px;

        &.more {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: 10px 0;
        }
      }
    }
  }
}
