#top-vue {
  display: inline-block;
  position: relative;
  top: -100px;
}

.student {

  .error {
    margin-top: 0px;
  }

  h2.sep {
    color: $blue;
    font-family: $montserrat;
    font-weight: 700;
  }

  &.iframe {
    iframe {
      width: 100%;
      height: 800px;
    }
  }

  &.notes {
    img {
      margin-bottom: 15px;
    }
  }

  &.documents {



    .documentation table {
      margin: 15px auto;

      tr {
        border-bottom: 1px solid $greyd;
        td, td a {
          color: $grey5;
        }
        &:hover td, &:hover td a {
          color: black;
          background-color: $greyf;
          text-decoration: none;

          .icon-download-pink {
            @include sprite($download-green)
          }
        }
      }
    }
  }

  &.choice {

    form {
      margin-top: 70px;
    }

    .logos {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;

      li {
        display: inline-block;
        margin: 10px;
      }
    }

    .actions {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      a {
        padding-top: 10px;
        display: block;
        width: 100%;
        background-color: $blue-light;
        color: white;
        position: relative;
        min-height: 80px;
        padding-left: 80px;
        margin-bottom: 15px;

        .enter {
          display: none;
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: white;
          font-family: $montserrat;
          font-weight: 700;
          padding-right: 10px;
          font-size: 1.5rem;
          .icon {
            position: relative;
            top: 1px;
            margin-left: 5px;
          }
        }

        &:hover {
          background-color: $blue;
          .enter {
            display: block;
          }
        }

        .label {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-family: $montserrat;
          font-weight: 700;
          font-size: 1.8rem;
        }

        & > .icon {
          position: absolute;
          left: 10px;
          top: 50%;
          transform-origin: 0 50%;
          transform: translateY(-50%)  scale(0.5);
        }

        &:nth-child(even){
          background-color: $green-light;

          &:hover {
            background-color: $green-dark;
          }
        }

        img {
          transform: scale(0.8);
          transform-origin: center center;
          transition: transform .2s ease-in-out;
        }
        a:hover img {
          transform: scale(1);
        }
      }
    }
  }



  .avatar {
    position: relative;
    display: block;
    width: 200px;
    margin: auto;
    margin-top: 15px;
    padding-top: 135px;
    margin-bottom: 25px;

    .img {
      position: absolute;
      display: block;

      &.user {
        border-radius: 100%;
        background-color: $blue-light;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
          border-radius: 100%;
          margin: 4px;
        }
      }

      &.bg {
        position: absolute;
        display: block;
        top: 23px;
        left: 0;
      }
    }
  }
}
