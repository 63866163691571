@keyframes next {
  0% {
    right: 5px;
  }
  5% {
    right: 10px;
  }
  85% {
    right: 10px;
  }
  90% {
    right: 5px;
  }
  95% {
    right: 10px;
  }
  100% {
    right: 5px;
  }
}

.articles-list {
  .article {
    padding: 0 15px;
    margin-bottom: 15px;

    &:nth-child(odd) h3 {
      background-color: $blue-light;
    }

    &:nth-child(even) h3 {
      background-color: $green;
    }

    a {
      display: block;
      background-color: $greyf;
      text-decoration: none;
      border: 1px solid white;

      h3 {
        font-family: $marcellus;
        font-size: 1.5rem;
        text-overflow: ellipsis;
        line-height: 1.6;
        padding: 10px 5px;
        text-align: left;
        color: #ffffff;
      }

      .article-content {
        .article-img {
          height: 150px;
          position: relative;
          overflow: hidden;
          width: 100%;

          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
          }
        }
        .description {
          width: 100%;
          padding: 10px;
          position: relative;
          .icon {
            position: absolute;
            bottom: 5px;
            right: 5px;
          }
          .icon-next {
            z-index: 10;
            // transform: rotate(180deg);
            transition: transform 0.3s ease;
          }
          .icon-more {
            // transform: rotate(0deg);
            // opacity: 1;
            z-index: 20;
            transition: transform 0.3s ease, opacity 0.3s ease;
          }
        }
      }

      &:hover {
        background-color: lighten($greyf, 2%);

        border-color: darken(white, 8%);

        p {
          color: black;
        }

        .icon {
          animation-duration: 2.5s;
          animation-name: next;
          animation-iteration-count: infinite;
        }

        .icon-next {
          transform: rotate(360deg);
        }

        .icon-more {
          transform: rotate(45deg);
          opacity: 0;
        }
      }
    }
  }
}
