// xs
.error {
  // margin-top: 120px;

  h1 {
    margin: 20px 0 0;
  }

  .btn {
    display: inline-block;
    background-color: $green;
    color: #fff;
    padding: 10px;
    margin: 15px;
    position: relative;
  }

}
