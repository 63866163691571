.col-carousel {
  padding: 0;
}
.bxslider-container {
  position: relative;
  background-color: $greye;
  margin-top: 0;
  min-height: 400px;

  .bx-controls-direction {
    //opacity: 0;
  }

  .bxslider-slider {
    position: relative;

    .bx-wrapper {
      margin: 0;
      border: 0;
      box-shadow: none;
      border-bottom: 2px solid $green;
    }
  }

  .bxslider-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &#bxslider-prev {
      left: 4%;
    }
    &#bxslider-next {
      right: 4%;
    }
  }

  .bxslider-content li {
    padding: 15px;

    h2 {
      color: $blue-dark;
    }

    p {
      color: $blue-dark;
      margin: 4px 0;
      padding-bottom: 45px;

      &.bxslider-link {
        position: absolute;
        bottom: 15px;
        left: 15px;
        padding-bottom: 0;

        a {
          display: inline-block;
          padding: 8px 0;
          .icon-arrow-blue {
            margin-left: 6px;
            position: relative;
            top: 5px;
          }

          &:hover {
            color: $green;
            .icon-arrow-blue {
              @extend .icon-arrow-green;
            }
          }
        }
      }
    }
  }

  .bxslider-pager {
    position: absolute;
    right: 15px;
    bottom: 15px;
    .bullet {
      display: inline-block;
      padding: 8px 10px;
      &.active .icon {
        @extend .icon-bullet-on;
      }
      &:hover .icon {
        @extend .icon-bullet-hover;
      }
    }
  }
}

.homelink {
  display: block;
  margin-bottom: 15px;
  max-height: 150px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    transform: translateY(-25%);
  }

  .homelink-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.homelink-blue .homelink-content h3 {
    background-color: $blue-light;
  }
  &.homelink-green .homelink-content h3 {
    background-color: $green;
  }

  h3 {
    font-family: $marcellus;
    font-size: 1.4rem;
    padding: 10px 5px;
    text-align: center;
    color: #ffffff;
  }

  .description {
    display: none;
  }
}

.testimonials {
  text-align: center;
  margin-bottom: 40px;

  .testimonial {
    position: relative;
    display: block;
    width: 200px;
    margin: auto;
    padding-top: 135px;
    margin-bottom: 25px;

    .img {
      position: absolute;
      display: block;

      &.user {
        border-radius: 100%;
        background-color: $blue-light;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
          border-radius: 100%;
          margin: 4px;
        }
      }

      &.bg {
        position: absolute;
        display: block;
        top: 23px;
        left: 0;
      }
    }

    h3 {
      font-family: $montserrat;
      font-weight: 700;
      color: $blue;
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-family: $marcellus;
      color: $blue-dark;
      text-align: center;
    }

    .description {
      display: none;
    }
  }
}

.logo-quality {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  div {
    align-self: center;
    img {
      margin: 10px;
      max-width: 300px;
    }
  }
}
