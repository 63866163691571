.notes {

  .custom-html {
    border: 1px solid black;
    padding: 10px;
    margin: 20px 0;
  }

  .left-bg {
    margin-bottom: 20px;
    a {
      display: block;
      padding-right: 20px;
      position: relative;
      width: 100%;
    }
    a .icon {
      display: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    a.active .icon {
      display: inline-block;
    }

    ul {
      li {
        padding: 0;
        padding-left: 30px;
        position: relative;
        margin: 5px 15px;
        line-height: 1.8rem;

        font-family: $montserrat;
        font-size: 1.5rem;
        color: $grey4;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(5px);
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background-color: $green;
        }
      }
    }
  }
}
