


.info-formation {
  margin-top: 15px;
  li {
    margin-bottom: 20px;
  }

  font-family: $montserrat;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4;

  strong {
    text-transform: uppercase;
    color: $blue;
  }

  a {
    color: $scorpion;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: normal;
  }
}

.training-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  a {
    position: relative;
    display: inline-block;
    font-family: bold;
    width: 95%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #d6d7d7;
    font-family: $montserrat;

    &:hover {
      text-decoration: none;
      background-color: $greye;
    }

    .name {
      color: $blue;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.3;
      border-bottom: 2px solid $green;
    }

    .content {
      color: $black;
      line-height: 1.3;
    }

    &:hover {
      .more {
        &:before {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 40px 40px;
          border-color: transparent transparent $pink transparent;
          z-index: 10;
        }

        .cross {
          position: absolute;
          bottom: 4px;
          right: 4px;
          &:before {
            position: absolute;
            bottom: 6px;
            right: 0;
            content: "";
            display: block;
            width: 16px;
            height: 4px;
            background-color: #FFF;
            z-index: 20;
          }

          &:after {
            position: absolute;
            bottom: 0;
            right: 6px;
            content: "";
            display: block;
            width: 4px;
            height: 16px;
            background-color: #FFF;
            z-index: 20;
          }
        }
      }
    }
  }


}



#calendar-list {
  margin-bottom: 30px;

  .training-date {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }

  .training-title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .training-category, .training-status span {
      color: $blue;
  }


}

.title-calendar {
  color: $blue;
  font-family: $montserrat;
  font-weight: bold;
  font-size: 2.4rem;
  padding-bottom: 10px;
}

.filter {
  margin-top: 20px;
  label {
    font-weight: bold;
    font-family: $montserrat;
    display: inline-block;
    padding-right: 20px;
  }
}

.paginate {
  text-align: center;
  li {
    display: inline;

    &.active a {
      color: $green;
    }
  }
  a {
    font-weight: bold;
    font-family: $montserrat;
    display: inline-block;
    padding: 10px;
    &:hover {
      color: $blue-dark;
      text-decoration: none;
    }
  }


  .turn-left {
    transform: rotate(180deg);
  }
}
