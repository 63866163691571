
.page-faq {

  .faq-search-container {
    width: 100%;
    margin: 20px auto;
    text-align: center;

    .faq-search {
      display: inline-block;
      min-width: 50%;
      @media (max-width: 770px) {
        margin-top: 30px;
        width: 100%;
      }
      padding: 0 0 0 8px;
      background-color: #e8edf0;

      .faq-flex {
        display: flex;
      }

      span {
        align-self: center;
      }

      input {
        width: 100%;
        align-self: center;
        margin: 0;
        margin-left: 8px;
        padding: 5px;
        background-color: #e8edf0;
        font-family: $marcellus;
        text-transform: uppercase;
        border: 1px solid #e8edf0;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 30px;

        &::placeholder {
          color: #b3b3b5;
        }
        &:focus {
          outline: none !important;
          border: 1px solid darken(#e8edf0, 10%);
        }
      }
    }
  }

  .subject {
    margin-bottom: 30px;
    display: block;
  }


    h3 {
      color: $blue;
      font-family: $marcellus;
      font-size: 2rem;
      text-align: center;
      border-bottom: 2px solid $green;
      padding-bottom: 10px;
    }



    .faq {
      position: relative;
      display: block;
      width: 100%;
      background-color: #fbfcf4;

      transition: background-color 0.2s ease-in-out;

      &:nth-child(even) {
        background-color: #ffffff;
      }

      .question {
        position: relative;
        display: block;
        transition: font-weight 0.2s ease-in-out, color 0.2s ease-in-out, padding 0.2s ease-in-out;
        width: 100%;
        font-family: $marcellus;
        font-size: 1.6rem;
        display: block;
        color: $blue-dark;
        padding: 0 40px 0 30px;

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 30px;

          &.icon-faq-up {
            transform: translateY(-50%) rotate(180deg);
            display: none;
          }
        }


        @media (max-width: 770px) {
          padding: 0 20px 0 10px;

          .icon {
            right: 0;
          }
        }

        &:hover {
          text-decoration: none;
          .icon-faq-right {
            display: none;
          }
          .icon-faq-up {
            display: inline-block;
          }
        }

        div {
          display: block;
          padding: 8px 0;
          transition: border-bottom 0.2s ease-in-out;
          border-bottom: 0px solid #d5d5d5;
        }
      }

      .response {
        padding: 15px 30px 0;
        display: none;

        img {
          aspect-ratio: auto;
          height: auto;
        }

        @media (max-width: 770px) {
          padding: 15px 10px 0;
        }

      }

      .faq-contact {
        display: none;
        padding: 15px 30px 0;
        justify-content: space-between;

        @media (max-width: 770px) {
          padding: 15px 10px 0;
        }

        a:first-child {
          text-decoration: underline;
          align-self: center;
        }

        .faq-switch {
          position: relative;
          display: inline-block;
          padding: 10px;
          display: none;
          right: -8px;
        }
      }

      &:hover, &.active {
        .question {
          font-weight: bold;
          color: $blue-dark;
          padding: 0 15px;
        }

        @media (max-width: 770px) {
          .question {
            padding: 0
          }
        }
      }


      &.active {
        background-color: #efefef;

        .question {
          div {
            border-bottom: 1px solid #d5d5d5;
          }
          .icon {
            display: none;

          }
        }
        .response {
          display: block;
        }


        .faq-contact {
          display: flex;

          a:first-child:hover {
            color: $blue-dark;
          }
          .faq-switch {
            display: inline-block;
            transition: transform .3s ease-in-out;
            transform: translateY(0px);

            &:hover {
              transform: translateY(-5px);
            }
          }
        }
      }
    }

}
