$blue: #2e5a6f;
$grey: #656464;

$marcellus: 'Marcellus', serif;
$breackpoint: 1000px;

.job-filters {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h3 {
    font-size: 0.9em;
    padding-bottom: 3px;
  }

  .select2-container {
    width: 100%;
  }

  & > div {
    width: 33%;
    padding: 10px 5px;

    select {
      width: 100%;
    }

    input {
      width: 100%;
      background-color: white;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: text;
      padding: 7px 10px;
      font-family: "Marcellus", serif;
    }
  }
}


.Icon {
  position: relative;
  top: -5px;
}

.styled-job {
  font-family: $marcellus;
  padding: 10px 35px 35px;
  background-color: #efeff0;


  @media (max-width: $breackpoint) {
    margin-top: 25px;
  }

  .job-header {
    @media (max-width: $breackpoint) {
      flex-direction: column;

      div {
        padding: 10px 0;
      }
    }

    display: flex;
    justify-content: space-between;
    min-height: 80px;
    padding-bottom: 0;
    margin-bottom: 5px;
    border-bottom: 2px solid #acc81b;

    div {
      align-self: center;
    }

    & > div:first-child {
      flex-shrink: 0;
      padding-right: 25px;
    }

    & > div:last-child {
      margin-left: auto;
    }
  }

  p,
  li {
    display: block;
    padding: 0;
    margin: 0 0 5px;
    font-family: $marcellus;
    font-size: 16px;
    color: #656464;
  }

  h2 {
    font-family: $marcellus;
    font-size: 2.1rem;
    font-weight: 700;
    margin: 20px 0;

    &.description {
      font-size: 2.1rem;
      color: #2e5a6f;
    }

    &.renseignements {
      font-size: 2.1rem;
      color: #acc81b;
    }
  }

  .JobContent {
    display: flex;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        padding: 3px 0;
        margin: 0;
      }
    }

    @media (max-width: $breackpoint) {
      flex-direction: column;
    }

    div:nth-child(1) {
      h3 {
        position: relative;
        left: -25px;
        display: flex;
        padding: 0;
        margin: 20px 0 0;
        font-family: $marcellus;
        font-size: 1.6rem;
        color: #656464;
      }
    }

    div:nth-child(2) {
      flex-shrink: 0;
      max-width: 400px;

      @media (min-width: $breackpoint) {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #d3d3d5;
      }

      h3 {
        margin: 50px 0 10px;
        font-family: Marcellus;
        font-size: 1.6rem;
        color: #656464;
      }
    }
  }

  .Mailto {
    display: block;
    padding: 7px 10px;
    font-family: $marcellus;
    font-size: 1.6rem;
    color: #efeff0;
    text-decoration: none;
    background-color: #2e5a6f;
    border-radius: 6px;
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(0);

    &:hover {
      box-shadow: 0 3px 3px 0 rgba(101, 101, 101, 0.6);
    }
  }

  .JobPhotos {
    margin-top: 20px;

    h3 {
      position: relative;
      left: -25px;
      display: flex;
      padding: 0;
      margin: 0 0 10px;
      font-family: $marcellus;
      font-size: 1.6rem;
      color: #656464;
    }
  }

  .JobPhotosUl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 0;
    margin: 0;
    list-style-type: none;

    a {
      display: block;
      width: 100px;
      height: 100px;
      margin: 5px;
      background-position: center center;
      background-size: cover;
      box-shadow: 0 3px 3px 0 rgba(101, 101, 101, 0.2);
      transition: box-shadow 0.3s ease-in-out;

      img {
        width: 100px;
        height: 100px;
      }
    }

    a:hover {
      box-shadow: 0 3px 3px 0 rgba(101, 101, 101, 0.6);
    }
  }


}







.jobs-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.job {
  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
  }

  a {
    position: relative;
    display: block;
    padding: 10px;
    margin: 10px;
    overflow: hidden;
    color: $blue;
    text-decoration: none;
    background-color: #efeff0;
    box-shadow: 0 3px 3px 0 rgba(101, 101, 101, 0.2);
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(0);

    &:hover {
      box-shadow: 0 3px 3px 0 rgba(101, 101, 101, 0.6);
      transform: translateY(-1px);
    }

    .job-card-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #acc81b;
      padding: 10px 0;

      div:first-child {
        // width: 100%;
      }

      .job-card-ref p {
        font-size: 1.2rem;
        line-height: 1.5;
        white-space: nowrap;
      }
    }

    h4 {
      font-family: 'Marcellus', serif;

      padding: 0;
      margin: 0;
      margin-right: 20px;
      font-size: 2.2rem;
      font-weight: 600;
    }

    p {
      font-family: 'Marcellus', serif;

      font-size: 1.6rem;
      color: $grey;
      margin: 0;
      padding: 0;
    }

    ul {
      list-style-type: disc;
      padding: 10px;
      margin: 0 20px;

      li {
        padding: 5px;
        font-family: 'Marcellus', serif;
      }
    }

    .cross {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 80px;
      height: 80px;
      background-color: $blue;
      transform: translate(50%, 50%) rotate(45deg);

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        display: block;
        width: 15px;
        height: 3px;
        content: "";
        background-color: #fff;
        transition: transform 0.1s ease-in-out;
      }

      &::before {
        transform: translate(10px, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(10px, -50%) rotate(135deg);
      }
    }

    &:hover .cross {
      &::before {
        transform: translate(9px, -8px) rotate(0deg);
      }

      &::after {
        transform: translate(15px, -2px) rotate(90deg);
      }
    }
  }
}



.Retour {
  display: flex;
  padding: 10px 0;
  margin-top: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  text-decoration: none;

  span {
    text-decoration: none;
    align-self: center;

    &:first-child {
      position: relative;
      left: 2px;
      top: 0;
      transition: left 0.2s ease-in-out;
    }
  }

  &:hover {
    text-decoration: none;
    span:last-child {
      text-decoration: underline;
    }

    span:first-child {
      text-decoration: none;
      left: -2px;
    }
  }
}
