
*[required="required"] + label::after, label.required::after {
  content: "*";
  color: red;
  display: inline-block;
  margin-left: 5px;
}

.flash {
  border-radius: 2px;
  border: 2px solid $green;
  color: $green;
  font-family: $montserrat;
  padding: 4px;

  &.flash-success {

  }

  &.flash-error {
    border-color: red;
    color: red;
  }

  &.flash-global {
    border-size: 5px;
    font-weight: 700;
    text-align: center;
  }

}


.send {
  position: relative;
  cursor: pointer;
  margin: 15px 0;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-decoration: none;
  color: #fff;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  text-align: center;
  outline: 0;
  font-family: $montserrat;
  font-size: 1.6rem;
  background-color: $green;
  border: 1px solid $green;

  &:hover {
    background-color: darken($green, 5%);
    border: 1px solid darken($green, 8%);
  }
}


.input-field {
  display: flex;
  flex-wrap: wrap;
  label {
    font-family: $montserrat;
    color: $grey9;
    // font-size: 1.8rem;
    order: -1;
  }

  position: relative;
  margin-top: 14px;
  padding-top: 0;

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="password"],
	input[type="tel"],
	input[type="number"],
  textarea {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $grey9;
		border-radius: 0;
		outline: none;
		height: 4rem;
		width: 100%;
		margin: 0 0 20px;
		padding: 5px 0 0;
		font-size: 1.8rem;
		box-shadow: none;
		box-sizing: content-box;
		transition: all 0.3s;
    font-family: $montserrat;

		& + label {
			color: $grey9;
			position: static;
			top: 28px;
			left: 0;
			font-size: 1.6rem;
      line-height: 1.5;
			cursor: text;
			transition: .2s ease-out;
    }
  }

  textarea {
    min-height: 100px;
    padding-top: 21px !important;
  }


  &.radio {
    display: inline-block;
    margin-bottom: 10px;

    label {
      position: relative;
      display: inline-block;
      padding: 5px 20px 5px 0;
      margin-right: 20px;
      cursor: pointer;
      font-size: 1.4rem;
      color: $grey4;

      &:after {
        position: absolute;
        display: block;
        content: "";
        right: 0;
        width: 10px;
        top: 50%;
        height: 10px;
        border: 1px solid $grey4;
        border-radius: 100%;
        transform: translateY(-50%);
		    transition: all 0.2s;
      }
    }

    input {
      position: fixed;
      left: -9000px;
    }

    input:checked + label:after {
      background-color: $green;
    }

  }

}
