.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 78px;
  background-color: #fefefe;
  z-index: 600;

  .burger {
    position: absolute;
    top: 10px;
    left: 10px;
    text-indent: -9999px;
    display: inline-block;
    width: 30px;
    height: 30px;
    float: left;

    span {
      top: 2px;
      @include burger(29px, 5px, 4px, $green, 3px);
    }

    &.is-active span {
      @include burger-to-cross;
    }
  }

  h1 {
    margin: 4px auto 0;
    background: url('/public/img/logo.svg') center center no-repeat;
    background-size: contain;
    text-indent: -9999px;
    width: 107px;
    height: 40px;
  }

  .fast-link {
    margin-top: 2px;
    width: 100%;
    border-top: 2px solid $green;
    padding-top: 1px;

    a {
      position: relative;
      display: block;
      width: (100% / 3);
      color: #fefefe;
      font-size: 1rem;
      height: 28px;
      text-decoration: none;
      text-align: center;
      padding-top: 4px;
      float: left;
      background-color: $green;
      text-transform: uppercase;
      font-weight: 700;
      font-family: $montserrat;
      line-height: 1.2em;

      .icon {
        display: none;
      }

      &:nth-child(1) {
        background-color: #60a8c3;
        padding: 1px;
        line-height: 28px;
      }
      &:nth-child(2) {
        background-color: $green;
      }
      &:nth-child(3) {
        background-color: $blue;
        padding: 1px;
        line-height: 28px;
      }

      img {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 26px;
      }
    }

    &.fast-link-logged {
      a {
        width: 50%;
      }
    }
  }

  .logout {
    display: none;
  }
}
