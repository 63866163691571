@import '../../sprites';

.icon {
  display: inline-block;
  @include sprite-image($users);
}

@each $sprite in $spritesheet-sprites {
  $sprite-name: nth($sprite, 10);
  .icon-#{$sprite-name} {
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .icon {
    @include sprite-image($users-2x);
    @include sprite-background-size($users);
  }

  @each $sprite in $retina-spritesheet-sprites {
    $sprite-name: nth($sprite, 1);
    .icon-#{$sprite-name} {
      @include sprite-position($sprite);
      @include sprite-width($sprite);
      @include sprite-height($sprite);
    }
  }
}
