
.page .documents .paginate {
  text-align: center;
  li {
    display: inline-block;
    font-family: $montserrat;
    margin: 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border: 1px solid $green;
    vertical-align: middle;
    border-radius: 2px;

    a {
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: 25px;
    }

    &:hover {
      border: 3px solid $green;
      a {
        line-height: 21px;
        text-decoration: none;
        color: $green;
      }
    }

    &.noborder {
      font-family: $marcellus;
      border: 0;
      font-size: 2.5rem;
      position: relative;
      top: -2px;
      width: 15px;
      a:hover {
        line-height: 25px;
      }
    }

    &.active {
      background-color: $green;
      a {
        color: white;
      }
    }
  }
}
