

h1, .h1-like {
  font-family: $montserrat;
  color: $blue-dark;
  font-size: 3rem;
  font-weight: 700;
}

h2, .h2-like {
  font-family: $marcellus;
  color: $green;
  font-size: 2.4rem;
  padding-bottom: 10px;
}

h2.sep {
  font-size: 2rem;
  text-align: center;
  margin: 20px 0 30px;
  padding: 5px 0 10px;
  position: relative;
  border-bottom: 2px solid $green;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -14px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 10;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -18px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 18px solid $green;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 5;
  }
}

h3, .h3-like {
  font-family: $montserrat;
  color: $grey4;
  font-size: 1.8rem;
  font-weight: 700;
}

p {
  font-family: $montserrat;
  color: $grey4;
  font-size: 1.5rem;
  line-height: 1.9rem;
}


.legend {
  font-family: $marcellus;
  font-size: 1.3rem;
  color: $grey4;
}

.dtable {
  margin: auto;
  display: table;
  .dcell {
    vertical-align: middle;
    display: table-cell;
  }
}

.hide {
  display: none;
}

a {
  text-decoration: none;
  color: $blue-light;

  &:hover {
    text-decoration: underline;
  }
}

.img-responsive {
  @include img-responsive();
}

#map {
  width: 100%;
  height: 200px;
  margin: 15px 0;
}

.text-center {
  text-align: center;
}



strong {
  font-weight: 700;
}

.clearfix {
  @include clearfix;
}

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $blue-dark;
  border-top: 1px solid $green;

  p {
    margin: 15px auto;
    color: white;

    a.btn {
      background-color: $green;
      color: white;
      padding: 5px;
      font-family: $montserrat;
      display: inline-block;
      margin: auto 15px;
    }
  }
}

.text-right {
  text-align: right;
}


.center {
  text-align: center;
}

.btn-default {
  display: inline-block;
  background-color: $green;
  color: #fff;
  padding: 10px 30px 10px 10px;
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon-breadcrumb {
    display: none;
  }
}


table {
  margin: 20px 0;
  th {
    color: $blue;
    font-family: $montserrat;
    font-weight: bold;
    font-size: 1.5rem;
    padding-bottom: 10px;
    border-bottom: 2px solid $green;
    text-align: left;
    text-transform: uppercase;
    padding: 5px;
  }

  tr {
    &:nth-child(even){
      background-color: $greyf;
    }
  }

  td {
    color: black;
    font-family: $montserrat;
    font-size: 1.5rem;
    text-align: left;
    padding: 10px;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}


@media screen and (max-width: 640px) {
  table,
  tbody,
  tr,
  th,
  td {
    display: block;
    width: auto;
  }
}

.search-blur {
  transition: filter .1s ease-in-out;
}

$searchTop: 8px;
$searchLeft: 10px;
$size: 2px;


.search-btn {
  position: fixed;
  display: block;
  right: 0px;
  top: 0px;
  margin: 0;
  // border: 3px solid $blue;
  // background-color: $green;
  // border-radius: 100%;
  transition: right .2s ease-in-out;
  z-index: 900;
  overflow: hidden;

  &-tablette {
    display: none;
  }

  &-mobile {
    display: block;
  }

  span {
    text-indent: 300px;
    display: inline-block;
    position: relative;
    margin: 8px;
    width: 32px;
    height: 34px;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      width: 16px;
      height: 16px;
      border: 5px solid $blue;
      border-radius: 100%;
      top: 0;
      left: 0;
      transition: border .1s linear;
    }

    &:after {
      width: 16px;
      height: 5px;
      background-color: $blue;
      border-radius: 5px;
      transform: rotate(45deg);
      top: 23px;
      left: 16px;
    }
  }
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.3;
    z-index: 10;
  }

  .search-field {
    z-index: 40;
    position: fixed;
    padding: 5px;
    top: 20px;
    left: 0;
    right: 0;
    input {
      width: 100%;
      font-size: 3rem;
      padding: 10px;
    }
  }


  a.search-close {
    display: block;
    z-index: 300;
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    border: 4px solid $blue;
    border-radius: 100%;
    transform: rotate(45deg);
    background-color: $green;


    &::before, &::after {
      position: absolute;
      display: block;
      content: "";
      background-color: $blue;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2px;
    }

    &::before {
      width: 80%;
      height: 4px;
    }

    &::after {
      height: 80%;
      width: 4px;
    }
  }

  ul {
    position: fixed;
    z-index: 3000;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    list-style-type: none;
    margin: 0;
    padding: 0 5px 70px;

    li {
      margin: 5px;

      a {
        border-radius: 5px;
        background-color: white;
        display: block;
        width: 100%;
        padding: 15px 5px;
        border-left: 4px solid $green;
        text-decoration: none;

        h3 {
          padding-bottom: 4px;
        }


        &:hover {
          h3 {
            color: $green
          }
          p {
            color: black;
          }
        }
      }

      &.search-read-more {
        margin-top: 15px;
        a {
          font-family: $montserrat;
          border-left: 4px solid $blue;
          position: relative;
          padding-left: 35px;
          width: 50%;

          &::before, &::after {
            background-color: $blue;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &::before {
            height: 20px;
            left: 15px;
            width: 4px;
          }
          &::after {
            height: 4px;
            width: 20px;
            left: 7px;
          }
        }
      }
    }
  }
}
