.album {

  padding-top: 15px;

  .left-bg {
    padding-top: 20px;
  }


  .page.album-show article ul li {
    a {
      .album-foot {
        display: block;
        background-color: transparent;
        text-align: right;
      }
    }
  }



  .page article {

    .album-title {
      margin: 5px 15px 20px;
      font-size: 2rem;
    }

    .return {
      font-size: 2rem;
      margin: 20px;
      .icon {
        transform: rotate(180deg);
        position: relative;
        top: 3px;
        margin-right: 5px;
      }
    }

    ul {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        display: inline-block;
        //width: 33%;
        width: 100%;

        a {
          position: relative;
          margin: 15px;
          padding: 0;

          .show {
            font-family: $montserrat;
            font-size: 1.4rem;
            margin: 3px;
            text-align: right;
            color: #fc0769;
            .icon {
              margin: 0;
              padding: 0;
              position: relative;
              top: 2px;
              left: 4px;
            }
          }


          &:before {
            content: "";
            display: block;
            border-top: 2px solid $greyc;
            position: absolute;
            left: 10px;
            right: 10px;
            top: -4px;
          }

          .part1 {
            margin-bottom: 4px;
            font-family: $montserrat;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.8rem;
          }

          .part2 {
            font-family: $montserrat;
            font-size: 1.4rem;
          }


          img {
            z-index: 10;
            margin: 0;
            padding: 0;
          }


          .album-foot {
            position: absolute;
            bottom: 0;
            padding: 10px;
            left: 0;
            right: 0;
            background-color: rgba(black, .8);
            color: white;
          }
        }

      }
    }
  }

}
