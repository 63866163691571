.page {

  .page-header {
    position: relative;
    @include clearfix;

    img {
      width: 100%;
      // min-height: 90px;
    }

    h1 {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .64);
      padding: 5px 15px;
      font-size: 2.5rem;
    }
  }

  .chapo {
    font-family: $montserrat;
    color: $grey4;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 20px 0;
  }

  .documents {
    margin-bottom: 25px;
    h2 {
      text-transform: uppercase;
      font-family: $montserrat;
      color: $blue;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 5px;
    }

    ul li a{
      display: block;
      padding: 5px;
      margin: 5px;
    }
  }

  .page-footer {
    margin-top: 40px;
    border: 3px solid $blue-light;
    padding: 15px;
    position: relative;

    h2 {
      position: absolute;
      top: -15px;
      left: 15px;
      text-transform: uppercase;
      background-color: #FFF;
      padding: 5px;
      font-family: $montserrat;
      color: $blue;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 5px;
    }

    p {
      font-size: 1.2rem;
    }
  }


  @import "project";
  @import "trombi-testimonial";
  @import "contact";
  @import "card";
  @import "documentation";
  @import "calendar";
}
