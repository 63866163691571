.contact {
  h2 {
    color: $blue;
    text-transform: uppercase;
    margin: 30px 0 0;
  }

  .is-subscribe {
    margin: 10px 0;
    input {
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
  }

}
