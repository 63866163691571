
.documentation {
  h2 {
    color: $blue;
    font-family: $montserrat;
    text-transform: uppercase;
  }

  .h3-like {
    margin-top: 60px;
  }

  table {
    width: 100%;
    font-family: $montserrat;

    th, td {
      text-align: center;
      vertical-align: middle;
      padding: 4px;
    }

    th {
      color: #7d7d7d;
      font-size: 1.2rem;
    }

    td {
      color: $grey5;
      font-size: 1.4rem;
      padding-bottom: 0;

      a {
        display: block;
      }
    }

    th:first-child, td:first-child {
      text-align: left;
    }
  }
}
