.trombi-testimonial {
  margin-top: 20px;

  .trombi-testimonial-header {
    margin-bottom: 20px;
    border-bottom: 2px solid #f2f2f3;
  }

  .who {

    h2 {
      color: $blue-dark;
      font-family: $montserrat;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 700;
    }
    p {
      color: $blue-dark;
      font-family: $montserrat;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }

  a.btn {
    display: inline-block;
    background-color: $green;
    color: #fff;
    padding: 8px;
    margin-top: 20px;
    font-family: $montserrat;
  }
}

.trombis {

  h2 {
    color: $blue;
    font-family: $montserrat;
    text-transform: uppercase;
  }

  .h3-like {
    margin-top: 40px;
  }

  a.trombi {
    display: block;
    background-color: $greye;
    padding: 10px;
    margin: 15px 0;
    @include clearfix;
    position: relative;

    p {
      font-size: 1.2rem;
    }

    h3 {
      color: $green;
      margin-bottom: 5px;
    }

    img {
      margin-right: 10px;
      float: left;
    }

    .more {

      &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 40px 40px;
        border-color: transparent transparent $pink transparent;
        z-index: 10;
      }

      .cross {
        position: absolute;
        bottom: 4px;
        right: 4px;
        &:before {
          position: absolute;
          bottom: 6px;
          right: 0;
          content: "";
          display: block;
          width: 16px;
          height: 4px;
          background-color: #FFF;
          z-index: 20;
        }

        &:after {
          position: absolute;
          bottom: 0;
          right: 6px;
          content: "";
          display: block;
          width: 4px;
          height: 16px;
          background-color: #FFF;
          z-index: 20;
        }
      }
    }
  }
}
